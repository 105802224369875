<template>
  <div class="header">
    <div class="header__left">
      <slot name="left"/>
    </div>
    <div class="header__center">
      <img class="header__logo-image" src="@/assets/images/logo-dark.png" alt="logo">
    </div>
    <div class="header__right">
      <slot name="right"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderCallRoom',
};
</script>

<style scoped lang="scss">
.header {
  display: none;
  height: 70px;
  border-bottom: 1px solid $color-border;
  align-items: center;
  padding: 0 15px;
  flex-shrink: 0;
  @media screen and (max-width: 800px) {
    display: flex;
  }

  &__left {
    flex-grow: 1;
    flex: 1;
  }

  &__center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex: 1;
  }

  &__right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    text-align: end;
  }

  &__logo-image {
    height: 30px;
  }
}
</style>
