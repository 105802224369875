export default {
  namespaced: true,

  state: {
    chatIsOpen: false,
    tutorFinishLessonModal: false,
  },
  getters: {
    chatIsOpen: (state) => state.chatIsOpen,
    tutorFinishLessonModal: (state) => state.tutorFinishLessonModal,
  },
  mutations: {
    UPDATE_CHAT_STATUS: (state, status) => { state.chatIsOpen = status; },
    UPDATE_TUTOR_FINISH_LESSON_MODAL_STATUS: (state, status) => { state.tutorFinishLessonModal = status; },
  },
  actions: {
    changeChatVisibility({ commit }, status) {
      commit('UPDATE_CHAT_STATUS', status);
    },
    changeTutorFinishLessonModalStatus({ commit }, status) {
      commit('UPDATE_TUTOR_FINISH_LESSON_MODAL_STATUS', status);
    },
  },
};
