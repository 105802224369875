export default function (axios) {
  return {
    async fetchUserData() {
      return await axios.get('/profile');
    },
    async fetchLessonData(lessonUUID) {
      return await axios.get(`/lessons/${lessonUUID}`);
    },
    async fetchServiceCallData(lessonUUID) {
      return await axios.get(`/service-calls/${lessonUUID}`);
    },
    async sendRecommendationToStudent(data) {
      return await axios.post('/profile/tutor/lesson-recommendations', data);
    },
    async sendReviewToTutor(reviewData) {
      return await axios.post('/reviews', reviewData);
    },
    async fetchViolationsList() {
      return await axios.get('/public/core/violation-types');
    },
    async makeViolationReport(reportData) {
      return await axios.post('/violations', reportData);
    },
    async sendBugReport(formData) {
      return await axios.post('/reports', formData);
    },
    async signupInLessonAsTutor(lessonData) {
      return await axios.post('/profile/tutor/lessons/connect', lessonData);
    },
    async signupInLessonAsStudent(lessonData) {
      return await axios.post('/profile/student/lessons/connect', lessonData);
    },
    async finishCallForTutor(lessonData) {
      return await axios.post('/profile/tutor/lessons/complete', lessonData);
    },
    async finishCallForStudent(lessonData) {
      return await axios.post('/profile/student/lessons/complete', lessonData);
    },
    async checkSingleConnectionAmongOneRoom(data) {
      return await axios.post('/check-for-single-connection-to-one-room', data);
    },
    async checkSingleConnectionAmongAllRooms(data) {
      return await axios.post('/check-for-single-connection-to-many-rooms', data);
    },
    async signupInServiceCall(roomUUID) {
      return await axios.post(`/service-calls/${roomUUID}/connect`);
    },
  };
}
