<template>
  <div class="connection-popup">
    <div class="connection-popup__container">
      <div class="connection-popup__text">{{ $t('connectionPopup.establishingAConnection') }}...</div>
      <ProgressRing
        class="connection-popup__progress-ring"
        :radius="35"
        :progress="30"
      />
    </div>
  </div>
</template>

<script>
import ProgressRing from '@/components/ProgressRing.vue';

export default {
  components: { ProgressRing },
};
</script>

<style lang="scss">
.connection-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px;
  background: #fff;
  z-index: 200;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    font-size: 18px;
    margin-bottom: 10px;
  }
  &__progress-ring {
    width: 70px;
    height: 70px;
    transform-origin: center;
    animation: 1s linear 0s infinite rotate-anim;
    transform-origin: center;
  }

  @keyframes rotate-anim {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
