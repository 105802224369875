<template>
  <div class="preview">
    <div class="preview__content">
      <LessonDataPreview v-if="isLessonRoom"/>
      <ServiceCallDataPreview v-if="serviceCallData"/>
      <div class="preview__video-container">
        <video
          class="preview__video"
          v-if="localCameraStream"
          :srcObject.prop="localCameraStream"
          autoplay
          muted
        ></video>
      </div>
    </div>
    <div class="preview__footer">
      <base-button
        :theme="isCameraEnable ? 'white' : 'white-accent'"
        :disabled="!isCameraExists"
        @click="toggleCameraStatus"
      >
        <i v-if="isCameraEnable" class="la la-video button-icon button__icon"></i>
        <i v-else class="la la-video-slash button-icon button__icon"></i>
      </base-button>
      <base-button
        :theme="isMicrophoneEnable ? 'white' : 'white-accent'"
        @click="toggleMicrophoneStatus"
        class="margin-left-10"
      >
        <i v-if="isMicrophoneEnable" class="las la-microphone button-icon button__icon"></i>
        <i v-else class="las la-microphone-slash button-icon button__icon"></i>
      </base-button>
      <base-button :theme="'default'" class="margin-left-10" @click="goToCallPage">
        {{ $t('previewPage.connect') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';
import LessonDataPreview from '@/components/LessonDataPreview.vue';
import ServiceCallDataPreview from '@/components/ServiceCallDataPreview.vue';
import helpers from '@/helpers';

const {
  VUE_APP_ENVIRONMENT: ENV,
  VUE_APP_ONE_ROOM_CONNECTION_CHECK: ONE_ROOM_CONNECTION_CHECK,
  VUE_APP_MANY_ROOMS_CONNECTION_CHECK: MANY_ROOMS_CONNECTION_CHECK,
} = process.env;

export default {
  components: {
    BaseButton,
    LessonDataPreview,
    ServiceCallDataPreview,
  },
  data: () => ({
    mediaDevices: null,
  }),
  async mounted() {
    if (
      !window.localStorage.getItem('authToken')
      || !window.localStorage.getItem('roomId')
      || !window.localStorage.getItem('roomType')
    ) {
      helpers.redirectOnFailure();
    }

    const authToken = window.localStorage.getItem('authToken');
    this.$axios.defaults.headers.common.Authorization = authToken;

    if (!this.getUserAuthToken) await this.setUserAuthToken(authToken);
    if (!this.userData) await this.setupUserData();
    if (!this.roomId) await this.setupRoomId(window.localStorage.getItem('roomId'));
    if (!this.roomType) await this.setRoomType(window.localStorage.getItem('roomType'));

    if (this.isLessonRoom) await this.setupLessonData();
    if (this.isServiceCall) await this.setupServiceCallData();

    let localCameraStream = null;
    try {
      this.mediaDevices = await navigator.mediaDevices.enumerateDevices();
      localCameraStream = await navigator.mediaDevices.getUserMedia(this.getConstraints());

      this.setIsCameraExists(this.isAnyMediaInput);
      if (!this.isAnyMediaInput) {
        this.toggleCameraStatus();
      }

      localCameraStream.getAudioTracks()[0].enabled = this.isMicrophoneEnable;
    } catch (e) {
      if (ENV === 'dev') console.log(e);
    }
    this.setLocalCameraStream(localCameraStream);
  },
  computed: {
    ...mapGetters({
      roomId: 'getRoomId',
      userData: 'getUserData',
      roomType: 'roomType',
      getUserAuthToken: 'getUserAuthToken',
      notifyStringStatus: 'getNotifyStringStatus',
      localCameraStream: 'getLocalCameraStream',
      isCameraEnable: 'isCameraEnable',
      isMicrophoneEnable: 'isMicrophoneEnable',
      isCameraExists: 'isCameraExists',
      serviceCallData: 'serviceCallData',
    }),
    isLessonRoom() {
      return this.roomType === 'lesson';
    },
    isServiceCall() {
      return this.roomType === 'service-call';
    },
    isAnyMediaInput() {
      return !!(
        this.mediaDevices
        && this.mediaDevices.find((md) => md?.kind === 'videoinput')
      );
    },
  },
  methods: {
    ...mapActions({
      setupUserData: 'setupUserData',
      setupRoomId: 'setupRoomId',
      setUserAuthToken: 'setUserAuthToken',
      setRoomType: 'setRoomType',
      changeNotifyStringStatus: 'changeNotifyStringStatus',
      setLocalCameraStream: 'setLocalCameraStream',
      toggleCameraStatus: 'toggleCameraStatus',
      toggleMicrophoneStatus: 'toggleMicrophoneStatus',
      signupInLesson: 'signupInLesson',
      setupLessonData: 'setupLessonData',
      setupServiceCallData: 'setupServiceCallData',
      setIsCameraExists: 'setIsCameraExists',
      checkSingleConnectionAmongOneRoom: 'checkSingleConnectionAmongOneRoom',
      checkSingleConnectionAmongAllRooms: 'checkSingleConnectionAmongAllRooms',
      signupInServiceCall: 'signupInServiceCall',
    }),
    async goToCallPage() {
      if (!this.localCameraStream) {
        this.$notify({ type: 'error', title: this.$t('previewPage.notify.getAccessToCamAndMic') });
        return;
      }

      switch (this.roomType) {
        case 'lesson': {
          await this.setupLessonData();

          const { data: { status } } = await this.signupInLesson();
          if (!status) {
            this.$notify({
              type: 'error',
              title: this.$t('previewPage.notify.canNotConnectToLesson'),
            });
            return;
          }

          // check that user does not connected to this rooms
          if (ONE_ROOM_CONNECTION_CHECK === 'true') {
            const { data: { isUserConnectedToThisRoom } } = await this.checkSingleConnectionAmongOneRoom({
              roomId: this.roomId,
              userId: this.userData.id,
            });
            if (isUserConnectedToThisRoom === 'true') {
              this.$notify({
                type: 'error',
                title: this.$t('previewPage.notify.youHaveAlreadyJoinedThisLesson'),
              });
              return;
            }
          }

          // check that user does not connected to other rooms
          if (MANY_ROOMS_CONNECTION_CHECK === 'true') {
            const { data: { isUserConnectedToAnyRoom } } = await this.checkSingleConnectionAmongAllRooms({
              userId: this.userData.id,
            });
            if (isUserConnectedToAnyRoom) {
              this.$notify({
                type: 'error',
                title: this.$t('previewPage.notify.youHaveAlreadyJoinedToSomeLesson'),
              });
              return;
            }
          }

          this.$router.push('/call');
          break;
        }
        case 'service-call': {
          const { status } = await this.signupInServiceCall();
          if (!status) {
            this.$notify({
              type: 'error',
              title: this.$t('previewPage.notify.youCanNotConnectToThisCall'),
            });
            return;
          }

          this.$router.push('/service-call');
          break;
        }
      }
    },
    getConstraints() {
      const constraints = {
        video: false,
        audio: true,
      };
      if (!this.isAnyMediaInput) { return constraints; }
      constraints.video = {
        width: 640,
        height: 480,
        frameRate: {
          max: 15,
        },
      };
      return constraints;
    },
    createEmptyVideoTrack({ width, height }) {
      const canvas = Object.assign(document.createElement('canvas'), { width, height });
      canvas.getContext('2d').fillRect(0, 0, width, height);
      const stream = canvas.captureStream();
      const track = stream.getVideoTracks()[0];
      return Object.assign(track, { enabled: false });
    },
  },
};
</script>

<style scoped lang="scss">
.preview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  &__header {
    display: flex;
    height: 70px;
    border-bottom: 1px solid $color-border;
    align-items: center;
    padding: 0 15px;
    flex-shrink: 0;
  }
  &__header-left {
    flex-grow: 1;
  }
  &__header-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  &__header-logo {
    height: 36px;
  }
  &__header-right {
    flex-grow: 1;
    display: flex;
    justify-content: end;
  }
  &__video {
    object-fit: cover;
  }
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  &__content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
  }
  &__video-container {
    max-width: 500px;
    margin: 0 auto 20px;
    width: calc(100% - 20px);
    > video {
      max-width: 500px;
      width: 100%;
      aspect-ratio: 4 / 3;
      background: #ccc;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
    }
  }
  &__footer {
    display: flex;
    height: 70px;
    border-top: 1px solid $color-border;
    align-items: center;
    padding: 0 15px;
  }
}
.button {
  &__icon {
    font-size: 22px;
  }
}
.margin-left-10 {
  margin-left: 10px;
}
</style>
