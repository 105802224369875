<template>
  <div
    class="button"
    :class="[
      `button--theme-${theme}`,
      `button--size-${size}`,
      {
        'button--fill': fill,
        'button--rounded': rounded,
        'button--disabled': disabled
      }
    ]"
  >
    <div v-if="$slots.left" class="button__slot-left">
      <slot name="left"></slot>
    </div>
    <slot name="default"/>
    <div v-if="$slots.right" class="button__slot-right">
      <slot name="right"/>
    </div>
<!--    <i class="las la-battery-three-quarters"></i>-->
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: () => 'default',
      validator(value) {
        return ['default', 'white', 'white-accent'].includes(value);
      },
    },
    fill: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: () => 'lg',
      validator(value) {
        return ['xs', 'sm', 'md', 'lg'].includes(value);
      },
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
  background: $color-accent;
  border-color: $color-accent;
  border-style: solid;
  cursor: pointer;
  user-select: none;

  // modifiers
  &__slot-left {
    margin-right: 10px;
    font-size: 22px;
    line-height: 18px;
  }
  &__slot-right {
    margin-left: 10px;
    font-size: 22px;
    line-height: 18px;

  }
  &--theme-default {
    color: #fff;
    background: $color-accent;
  }
  &--theme-white {
    color: $color-text;
    background: #fff;
    border-color: $color-border;
  }
  &--theme-white-accent {
    color: $color-accent;
    background: #fff;
    border-color: $color-accent;
  }
  &--size-xs {
    padding: 4px 6px;
  }
  &--size-sm {
    padding: 6px 10px;
  }

  &--size-md {
    padding: 10px 15px;
  }

  &--size-lg {
    padding: 14px 18px;
  }
  &--fill {
    flex-grow: 1;
  }
  &--rounded {
    border-radius: 20px;
  }
  &--disabled {
    pointer-events: none  !important;
    background: #eee  !important;
    border: 2px solid #eee !important;
    color: #aaa !important;
  }
}
</style>
