<template>
  <div
    class="message"
    :class="{'message--is-you': messageAuthorYou}"
  >
    <div class="message__text" v-html="messageText"></div>
    <div class="message__footer">
      <span v-if="isTutor" class="message__tutor-icon">
        <i class="las la-graduation-cap"></i>
      </span>
      <span class="message__author">{{ authorName }}</span>&emsp;{{ messageDate }}
      </div>
  </div>
</template>

<script>
export default {
  name: 'ChatMsg',
  props: {
    // TODO: написать валидаторы для свойств
    messageId: {
      type: String,
      required: true,
    },
    messageAuthorYou: {
      type: Boolean,
      required: true,
    },
    authorName: {
      type: String,
      required: true,
    },
    authorId: {
      type: Number,
      required: true,
    },
    messageType: {
      type: String,
      required: true,
    },
    messageText: {
      type: String,
      required: true,
    },
    messageDate: {
      type: String,
      required: true,
    },
    isTutor: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.message {
  background: #eee;
  padding: 16px;
  border-radius: 15px 15px 15px 0;
  border: 1px solid rgba(0,0,0,.1);

  &--is-you {
    border-radius: 15px 15px 0 15px;
    background-color: $color-accent-opacity;
    color: $color-accent;
    border: 1px solid $color-accent-opacity;

    & .message__text {
      color: $color-accent;

      a {
        color: $color-accent;
        opacity: 0.85;
      }
    }
  }

  &__text {
    font-size: 16px;
    word-wrap: break-word;
    word-break: break-word;
    color: $color-text;

    a {
      color: $color-text;
      opacity: 0.85;
      word-break: break-word;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 6px;
    font-weight: 300;
    opacity: 0.5;
  }

  &__tutor-icon {
    font-size: 20px;
    margin-right: 4px;
  }

  &__author {
    font-size: 13px;
    font-weight: 400;
  }
}
</style>
