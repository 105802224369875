import { createApp } from 'vue';
import vSelect from 'vue-select';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Notifications from '@kyvg/vue3-notification';
import i18n from '@/utils/i18n';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import store from './store';
import socket from './plugins/socketIO';
import axios from './plugins/axios';

import 'vue-select/dist/vue-select.css';
import '@/styles/vue-select-override.scss';

const {
  VUE_APP_SERVER_URL,
  VUE_APP_BACKEND_API,
} = process.env;

const app = createApp(App)
  .use(i18n)
  .use(axios, {
    axios: { baseURL: VUE_APP_BACKEND_API },
    axiosSignalingServer: { baseURL: VUE_APP_SERVER_URL },
  })
  .use(store)
  .use(router)
  .use(socket, { serverURL: VUE_APP_SERVER_URL })
  .use(Notifications)
  .use(VueCookies)
  .component('v-select', vSelect)
  .component('QuillEditor', QuillEditor)
  .mount('#app');

// save reference to app vm in store
store.$app = app;
