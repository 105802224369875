<template>
  <div v-if="isShown" class="overflow" @click.self="closePopupHandler">
    <div v-if="isShown" class="report-popup">
      <div class="report-popup__close-button" @click="closePopupHandler">
        <i class="las la-times"></i>
      </div>
      <div class="report-popup__title">
        {{ $t('reportViolationPopup.title') }}
      </div>
      <div class="report-popup__title-2">
        {{ $t('reportViolationPopup.popupMainText') }}
      </div>
      <div class="report-popup__field-container">
        <div class="report-popup__field-title">
          {{ $t('reportViolationPopup.selectViolation') }}
        </div>
        <v-select
          class="v-select-comp"
          :options="getViolationsList"
          @option:selected="selectViolation"
          :clearable="false"
          label="name"
        >
          <template #no-options>
            {{ $t('reportViolationPopup.noAvailableOptions') }}
          </template>
        </v-select>
      </div>
      <div class="report-popup__field-container">
        <div class="report-popup__field-title">
          {{ $t('reportViolationPopup.selectUser') }}
        </div>
        <v-select
          class="v-select-comp"
          :options="otherConnectedMembers"
          label="firstname"
          @option:selected="selectUser"
          :clearable="false"
          :reduce="user => user.id"
        >
          <template #no-options>
            {{ $t('reportViolationPopup.noAvailableOptions') }}
          </template>
        </v-select>
      </div>
      <div class="report-popup__field-container">
        <div class="report-popup__field-title">
          {{ $t('reportViolationPopup.describeProblem') }}
        </div>
        <textarea
          v-model="form.violationDescription"
          class="report-popup__input-textaria"
        ></textarea>
      </div>
      <base-button class="send-button" :theme="'default'" @click="reportViolation">
        {{ $t('reportViolationPopup.violationReport') }}
      </base-button>
    </div>
  </div>

</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import { mapActions, mapGetters } from 'vuex';

const { VUE_APP_ENVIRONMENT: ENV } = process.env;

export default {
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  data: () => ({
    form: {
      selectedViolationId: null,
      selectedUserId: null,
      violationDescription: '',
    },
  }),
  mounted() {
    this.setViolationsList();
  },
  computed: {
    ...mapGetters({
      getViolationsList: 'getViolationsList',
      otherConnectedMembers: 'getOtherConnectedMembers',
      lessonId: 'getLessonId',
    }),
  },
  methods: {
    ...mapActions({
      setViolationsList: 'setViolationsList',
      toggleReportViolationPopup: 'toggleReportViolationPopup',
      makeViolationReport: 'makeViolationReport',
    }),
    selectUser(selectedUser) {
      this.form.selectedUserId = selectedUser.id;
    },
    selectViolation(violation) {
      this.form.selectedViolationId = violation.id;
    },
    reportViolation() {
      if (
        this.form.violationDescription
        && this.form.selectedViolationId
        && this.form.selectedUserId
      ) {
        this.makeViolationReport({
          lesson_id: this.lessonId,
          violation_type_id: this.form.selectedViolationId,
          violator_ids: [this.form.selectedUserId],
          text: this.form.violationDescription,
        })
          .then(() => {
            this.$notify({ type: 'success', title: this.$t('reportViolationPopup.notify.resultSuccess') });
            this.form.selectedViolationId = null;
            this.form.selectedUserId = null;
            this.form.violationDescription = '';
            this.toggleReportViolationPopup();
          })
          .catch((e) => {
            if (ENV === 'dev') console.log(e);
            const errorsArr = Object.values(e.response.data.errors).flat();
            if (errorsArr?.[0]) {
              this.$notify({ type: 'error', title: errorsArr[0] });
            }
          });
      }
    },
    closePopupHandler() {
      this.form.violationDescription = '';
      this.form.selectedUserId = null;
      this.form.selectedViolationId = null;
      this.$emit('closeHandler');
    },
  },
};
</script>

<style scoped lang="scss">
.overflow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //bottom: 70px;
  z-index: 200;
  //background: red;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  overflow-y: auto;
}
.report-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  max-width: 500px;
  border: 2px solid $color-border;

  &__close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: $color-accent;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    &:hover {
      background: #eee;
    }
  }

  &__title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
    padding: 0 40px;
  }

  &__title-2 {
    font-size: 16px;
    text-align: center;
    color: $color-text;
    margin-bottom: 20px;
  }

  &__field-container {
    width: 100%;
    margin-top: 18px;
  }

  &__field-title {
    margin-bottom: 5px;
  }

  &__input-textaria {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    border-radius: 4px;
    outline: none;
    border-color: #ccc;
    resize: none;
  }

  &__item {
    color: $color-text;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      background: $color-accent;
      color: #ffffff;
    }
  }
}
.send-button {
  margin-top: 20px;
}
</style>
