<template>
  <div class="chat">
    <header class="chat__header">
      {{ $t('chat.lessonChat') }}
      <div class="chat__close-btn" @click="closeChat">
        <i class="las la-times"></i>
      </div>
    </header>
    <div class="chat__msgs-container" ref="msgContainer">
      <NoMessages v-if="messagesList && messagesList.length === 0"/>
      <template v-else>
        <div
          v-for="messageData of messagesList"
          :key="messageData.id"
          class="chat__msg-container"
          :class="{'chat__msg-container--author-you': messageData.authorId === userData.id}"
        >
          <ChatTextMsg
            :messageId="messageData.messageId"
            :messageAuthorYou="messageData.authorId === userData.id"
            :authorName="messageData.authorName"
            :authorId="messageData.authorId"
            :messageType="messageData.messageType"
            :messageText="formatText(messageData.messageText)"
            :messageDate="this.getConvertedDateToLocalTimezone(messageData.messageDate)"
            :isTutor="messageData.isTutor"
          ></ChatTextMsg>
        </div>
      </template>
    </div>
    <div class="chat__btns-container">
      <input
        class="chat__msg-input"
        type="text"
        v-model="messageText"
        ref="msgInput"
        v-on:keypress.enter.exact="sendMessage"
      >
      <base-button
        size="xs"
        :rounded="false"
        @click="sendMessage"
      >
        <i class="las la-paper-plane"></i>
      </base-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import ChatTextMsg from '@/components/Chat/ChatTextMsg.vue';
import BaseButton from '@/components/BaseButton.vue';
import NoMessages from '@/components/Chat/NoMessages.vue';

export default {
  name: 'ChatComponent',
  emits: ['closeChat'],
  components: {
    NoMessages,
    BaseButton,
    ChatTextMsg,
  },
  data() {
    return {
      messagesList: [],
      messageText: '',
    };
  },
  mounted() {
    document.addEventListener('keydown', this.escPressHandler);

    this.$socket.on('UPDATE_CHAT_HISTORY', this.updateChatHistoryHandler);

    this.$socket.emit('REQUEST_CHAT_HISTORY', {
      roomId: this.roomId,
    });
  },
  unmounted() {
    document.removeEventListener('keydown', this.escPressHandler);
  },
  computed: {
    ...mapGetters({
      roomId: 'getRoomId',
      userData: 'getUserData',
    }),
  },
  methods: {
    escPressHandler(event) {
      event = event || window.event;
      if (event.keyCode === 27) {
        this.closeChat();
      }
    },
    updateChatHistoryHandler(data) {
      this.messagesList = data;
      this.$nextTick(() => {
        if (this.$refs.msgContainer) {
          this.$refs.msgContainer.scrollTop = this.$refs.msgContainer.scrollHeight;
        }
      });
    },
    sendMessage() {
      if (!this.messageText) return;

      const safetyMessage = this.makeXSSSafetyString(this.messageText);

      this.$socket.emit('SEND_MESSAGE', {
        roomId: this.roomId,
        userId: this.userData.id,
        msgData: {
          messageId: uuidv4(),
          authorName: `${this.userData.firstname} ${this.userData.lastname[0]}.`,
          authorId: this.userData.id,
          messageType: 'text',
          messageText: safetyMessage,
          messageDate: moment.utc().toDate(),
          isTutor: this.userData.role === 'tutor',
        },
      });
      this.messageText = '';
      this.$refs.msgInput.focus();
    },
    closeChat() {
      this.$emit('closeChat');
    },
    getConvertedDateToLocalTimezone(date) {
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return moment(date)
        .tz(localTimezone)
        .format('HH:mm');
    },
    makeXSSSafetyString(string) {
      return string.replace(/<\/?[^>]+>/ig, '');
    },
    formatText(rawString) {
      const formattedString = this.appendHTMLLinksToText(rawString);
      return formattedString;
    },
    appendHTMLLinksToText(rawText) {
      const urlRegex = /(((https?:\/\/)|(www\.))[^]+\.\S+)/g;
      const text = rawText.replace(urlRegex, (url, b, c) => {
        const urlValid = (c === 'www') ? `http://${url}` : url;
        return `<a href="${urlValid}" target=" _blank">${url} </a>`;
      });
      return rawText !== '.' ? text : '';
    },
  },
};
</script>

<style lang="scss">
.chat {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 400px;
  bottom: 70px;
  background: #fff;
  border-right: 1px solid $color-border;
  @media screen and (max-width: 400px) {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $color-border;
    font-size: 18px;
  }

  &__close-btn {
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fb236a;
    color: #fff;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 16px;
  }

  &__msgs-container {
    flex-grow: 1;
    padding: 20px;
    border-bottom: 1px solid $color-border;
    overflow: auto;
  }

  &__msg-container {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;

    &:last-child {
      margin-bottom: 0;
    }

    &--author-you {
      justify-content: flex-end;
    }
  }

  &__btns-container {
    height: 60px;
    padding: 15px;
    display: flex;
  }

  &__msg-input {
    height: 30px;
    border: 1px solid #ccc;
    margin-right: 8px;
    border-radius: 5px;
    flex-grow: 1;
    outline: none;
    text-indent: 5px;
  }
}
</style>
