<template>
  <div class="footer">
    <div class="footer__left">
      <slot name="left">
        <img class="footer__logo-image" src="@/assets/images/logo-dark.png" alt="logo">
      </slot>
    </div>
    <div class="footer__center">
      <slot name="center"/>
    </div>
    <div class="footer__right">
      <slot name="right"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterCallRoom',
};
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  flex-shrink: 0;
  padding: 0 15px;
  border-top: 1px solid $color-border;

  &__logo-image {
    height: 30px;
  }

  &__left {
    flex: 1;
    display: flex;
    justify-content: start;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__center {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  &__right {
    flex: 1;
    display: flex;
    text-align: end;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}
</style>
