<template>
  <div v-if="isShown" class="overflow" @click.self="closePopupHandler">
    <div v-if="isShown" class="report-popup">
      <div class="report-popup__close-button" @click="closePopupHandler">
        <i class="las la-times"></i>
      </div>
      <div class="report-popup__title">
        {{ $t('reportBugPopup.reportAProblem') }}
      </div>
      <div class="report-popup__title-2">
        {{ $t('reportBugPopup.popupMainText') }}

      </div>
      <div class="report-popup__field-container">
        <textarea
          v-model="form.reportText"
          class="report-popup__input-textaria"
          :placeholder="$t('reportBugPopup.subscribeProblem')"
        ></textarea>
      </div>
      <div>
        <input
          class="report-popup__hidden-input"
          @change="setFile"
          type="file"
          accept="image/jpeg, image/jpg"
          :size="maxImgSize"
          ref="fileInput"
        >
        <base-button
          size="sm" :rounded="false"
          :theme="'white-accent'"
          :fill="false"
          @click="$refs.fileInput.click()"
        >{{ $t('reportBugPopup.uploadImage') }}</base-button>
        {{ form.file?.name }}
        <div v-if="form.file?.size > maxImgSize" class="report-popup__file-size-error">
          {{ $t('reportBugPopup.bigFileMsg') }}
        </div>
      </div>
      <base-button class="send-button" :theme="'default'" @click="send">
        {{ $t('reportBugPopup.send') }}
      </base-button>
    </div>
  </div>

</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import { mapActions, mapGetters } from 'vuex';

const { VUE_APP_ENVIRONMENT: ENV } = process.env;

export default {
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  data: () => ({
    maxImgSize: 1048576,
    form: {
      reportText: '',
      file: null,
    },
  }),
  computed: {
    ...mapGetters({
      lessonId: 'getLessonId',
    }),
  },
  methods: {
    ...mapActions({
      sendBugReport: 'sendBugReport',
      toggleReportBugPopup: 'toggleReportBugPopup',
    }),
    send() {
      if (
        !this.form.reportText
        || (this.form.file && this.form.file.size > this.maxImgSize)
      ) return;

      const fd = new FormData();
      fd.append('type', 'lesson'); // required for lesson-service
      fd.append('text', this.form.reportText);
      if (this.form.file) fd.append('image', this.form.file, this.form.file.name);

      this.sendBugReport(fd)
        .then(() => {
          this.$notify({ type: 'success', title: this.$t('reportBugPopup.notify.resultSuccess') });
          this.form.reportText = '';
          this.form.file = null;
          this.toggleReportBugPopup(false);
        })
        .catch((e) => {
          if (ENV === 'dev') console.log(e);
          const errorsArr = Object.values(e.response.data.errors).flat();
          if (errorsArr?.[0]) {
            this.$notify({ type: 'error', title: errorsArr[0] });
          }
        });
    },
    closePopupHandler() {
      this.form.reportText = '';
      this.form.file = null;
      this.$emit('closeHandler');
    },
    setFile(e) {
      const file = e.target.files[0];
      this.form.file = file;
    },
  },
};
</script>

<style scoped lang="scss">
.overflow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //bottom: 70px;
  z-index: 200;
  //background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  padding: 20px;
  overflow-y: auto;
}
.report-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  max-width: 500px;
  border: 2px solid $color-border;

  &__close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: $color-accent;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    &:hover {
      background: #eee;
    }
  }

  &__title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
    padding: 0 40px;
  }

  &__title-2 {
    font-size: 16px;
    text-align: center;
    color: $color-text;
    margin-bottom: 20px;
  }

  &__field-container {
    width: 100%;
    margin-top: 18px;
  }

  &__input-textaria {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    border-radius: 4px;
    outline: none;
    border-color: #ccc;
    resize: none;
    margin-bottom: 10px;
  }

  &__hidden-input {
    display: block;
    height: 0;
    width: 0;
    opacity: 0;
  }

  &__file-size-error {
    font-size: 12px;
    color: red;
    margin-top: 5px;
  }
}
.send-button {
  margin-top: 20px;
}
</style>
