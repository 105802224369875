<template>
  <div></div>
</template>

<script>
const { VUE_APP_TEME_URL } = process.env;

export default {
  mounted() {
    window.location.href = VUE_APP_TEME_URL || 'https://dev.teachme.com.ua/';
  },
};
</script>
