<template>
  <div class="leave-call">
    <div class="leave-call__header">
      <div class="leave-call__header-left"></div>
      <div class="leave-call__header-center">
        <img class="leave-call__header-logo" src="@/assets/images/logo-dark.png" alt="logo">
      </div>
      <div class="leave-call__header-right"></div>
    </div>
    <div class="leave-call__content">
      <div class="review">
        <div class="review__title">{{ $t('leaveCallPageTutor.lessonCompleted') }}</div>
        <div v-if="students.length !== 0" class="review__content">
          <div class="review__content-text">
            {{ $t('leaveCallPageTutor.leaveRecommendation') }}
          </div>
          <div v-if="students.length > 1" class="review__student-counter">
            {{ `${$t('leaveCallPageTutor.leaveRecommendation')} ${selectedStudent + 1}/${students.length}` }}
          </div>
          <div
            v-for="(student, index) of students"
            :key="student.id"
            v-show="index === selectedStudent"
          >
            <div class="review__header-block">
              <img
                v-if="student.avatar"
                class="review__header-block-avatar"
                :src="student.avatar"
                alt="avatar"
              >
              <div
                v-if="student.firstname && student.lastname"
                class="review__header-block-username"
              >
                {{ `${student.firstname} ${student.lastname[0]}.` }}
              </div>
            </div>
            <div class="review__text-editor-container">
<!--              <QuillEditor-->
<!--                ref="htmlEditor"-->
<!--                theme="snow"-->
<!--                toolbar="minimal"-->
<!--                :placeholder="'Рекомендация...'"-->
<!--                v-model:content="value"-->
<!--                paste-as-text="true"-->
<!--              />-->
              <textarea
                id="recommendation"
                class="review__textarea"
                :placeholder="$t('leaveCallPageTutor.recommendations') + '...'"
                v-model="students[index].recomendation"
              ></textarea>
            </div>
          </div>
<!--          <div class="review__text-editor-container">-->
<!--            <QuillEditor-->
<!--              theme="snow"-->
<!--              toolbar="minimal"-->
<!--              :placeholder="'Рекомендация...'"-->
<!--              v-model:conten="students[0].recomendation"-->
<!--            />-->
<!--          </div>-->
          <div v-if="students  && students.length > 1" class="review__student-nav">
            <base-button
              class="review__student-nav-btn review__student-prev"
              :theme="'white-accent'"
              @click="prevStudent"
              fill
              :rounded="false"
              :size="'md'"
              :disabled="selectedStudent <= 0"
            >{{ $t('leaveCallPageTutor.prev') }}</base-button>
            <base-button
              class="review__student-nav-btn review__student-next"
              :theme="'white-accent'"
              @click="nextStudent"
              fill
              :rounded="false"
              :size="'md'"
              :disabled="selectedStudent >= students.length - 1"
            >{{ $t('leaveCallPageTutor.next') }}</base-button>
          </div>
          <base-button @click="sendRecommendations" fill>{{ $t('leaveCallPageTutor.send') }}</base-button>
        </div>
        <base-button @click="redirect" :theme="'white'">{{ $t('leaveCallPageTutor.goToTeme') }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';

const { VUE_APP_TEME_URL, VUE_APP_ENVIRONMENT: ENV } = process.env;

export default {
  components: {
    BaseButton,
  },
  mounted() {
    this.students = this.lessonMembers
      .filter((member) => member.role === 'student')
      .filter((student) => student.status === 'connected' || student.status === 'completed');

    this.students.forEach((student) => { student.recomendation = ''; });

    if (window.localStorage.getItem('roomId')) {
      window.localStorage.removeItem('roomId');
      window.localStorage.setItem('redirectIfPressBack', '/leave-call-tutor');
    }
  },
  data: () => ({
    selectedStudent: 0,
    students: [],
  }),
  methods: {
    ...mapActions({
      sendRecommendationToStudent: 'sendRecommendationToStudent',
    }),
    redirect() {
      window.location = VUE_APP_TEME_URL;
    },
    prevStudent() {
      if (this.selectedStudent <= 0) return;
      this.selectedStudent -= 1;
    },
    nextStudent() {
      if (this.selectedStudent >= this.students.length - 1) return;
      this.selectedStudent += 1;
    },
    sendRecommendations() {
      const promises = [];
      this.students
        .filter((student) => student.recomendation !== '')
        .forEach((student) => {
          promises.push(
            this.sendRecommendationToStudent({
              lesson_id: this.getLessonId,
              student_id: student.id,
              text: student.recomendation,
            }),
          );
        });
      Promise.all(promises)
        .then(() => {
          this.$notify({ type: 'success', title: this.$t('leaveCallPageTutor.notify.resultSuccess') });
          setTimeout(this.redirect.bind(this), 1000);
        })
        .catch((e) => {
          if (ENV === 'dev') console.log(e);
          this.$notify({ type: 'success', title: this.$t('leaveCallPageTutor.notify.resultFailed') });
        });
    },
  },
  computed: {
    ...mapGetters({
      lessonMembers: 'getAllLessonMembers',
      getLessonId: 'getLessonId',
    }),
  },
};
</script>

<style scoped lang="scss">
.leave-call {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    height: 70px;
    border-bottom: 1px solid $color-border;
    align-items: center;
    padding: 0 15px;
    flex-shrink: 0;
  }
  &__header-left {
    flex-grow: 1;
  }
  &__header-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  &__header-logo {
    height: 36px;
  }
  &__header-right {
    flex-grow: 1;
    display: flex;
    justify-content: end;
  }
  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    padding: 15px;
    @media screen and (orientation: landscape) and (max-height: 500px) {
      align-items: flex-start;
      overflow: auto;
    }
  }
}
.review {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  text-align: center;
  @media screen and (orientation: landscape) and (max-height: 500px) {
    margin-top: 20px;
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    color: $color-text;
    margin-bottom: 20px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-border;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 25px;
  }
  &__content-text {
    font-size: 18px;
  }
  &__student-counter {
    color: #888;
  }
  &__header-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  &__header-block-avatar {
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 5px;
    border: 3px solid $color-accent;
    object-fit: cover;
  }
  &__header-block-username {
    font-size: 18px;
    margin-bottom: 10px;
  }
  &__text-editor-container {
    margin-bottom: 15px;
  }
  &__textarea {
    width: 100%;
    resize: none;
    color: #474747;
    padding: 10px 10px;
    border: 2px solid $color-border;
    border-radius: 8px;
    height: 120px;
    outline: none;
  }
  &__student-nav {
    display: flex;
    margin: 0 -5px;
    margin-bottom: 20px;
  }
  &__student-nav-btn {
    margin: 0 5px;
  }
}
</style>
