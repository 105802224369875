import { createStore } from 'vuex';
import lessonModule from './lessonModule';
import lessonTimerModule from './lessonTimerModule';
import modalsModule from './modalsModule';
import api from '../api/index';

const { VUE_APP_ENVIRONMENT: ENV } = process.env;

export default createStore({
  state: {
    roomId: null,
    userAuthToken: null,
    roomType: null,
    serviceCallData: null,
    userData: null,
    notifyStringStatus: true,
    localCameraStream: null,
    isCameraEnable: true,
    isCameraExists: false,
    isMicrophoneEnable: true,
    localScreenStream: null,
    contextMenuIsShown: false,
    reportViolationPopupIsShown: false,
    lessonId: null,
    lessonMembers: [],
    violationsList: [],
    reportBugPopupIsShown: false,
  },
  getters: {
    getRoomId: (state) => state.roomId,
    getUserAuthToken: (state) => state.userAuthToken,
    roomType: (state) => state.roomType,
    serviceCallData: (state) => state.serviceCallData,
    getNotifyStringStatus: (state) => state.notifyStringStatus,
    getLocalCameraStream: (state) => state.localCameraStream,
    isCameraEnable: (state) => state.isCameraEnable,
    isCameraExists: (state) => state.isCameraExists,
    isMicrophoneEnable: (state) => state.isMicrophoneEnable,
    getLocalScreenStream: (state) => state.localScreenStream,
    getUserData: (state) => state.userData,
    getContextMenuIsShown: (state) => state.contextMenuIsShown,
    getReportViolationPopupIsShown: (state) => state.reportViolationPopupIsShown,
    getAllLessonMembers: (state) => state.lessonMembers,
    getLessonId: (state) => state.lessonId,
    getViolationsList: (state) => state.violationsList,
    getOtherConnectedMembers: (state) => state.lessonMembers
      .filter((member) => member.id !== state.userData.id)
      .filter((member) => member.status === 'connected' || member.status === 'completed'),
    getReportBugPopupIsShown: (state) => state.reportBugPopupIsShown,
    isMembersFromOppositeSide: (state) => {
      const { userData, lessonMembers } = state;
      if (!userData) return false;
      const oppositeRole = userData.role === 'student' ? 'tutor' : 'student';
      return !!lessonMembers.find((member) => member.role === oppositeRole
          && (member.status === 'connected' || member.status === 'completed'));
    },
    getMembersFromOppositeSide: (state) => {
      const { userData, lessonMembers } = state;
      if (!userData) return false;
      const oppositeRole = userData.role === 'student' ? 'tutor' : 'student';
      return lessonMembers.filter((member) => member.role === oppositeRole);
    },
  },
  mutations: {
    SETUP_ROOM_ID: (state, roomId) => { state.roomId = roomId; },
    CHANGE_NOTIFY_STRING_STATUS: (state, newStatus) => { state.notifyStringStatus = newStatus; },
    SET_LOCAL_CAMERA_STREAM: (state, stream) => { state.localCameraStream = stream; },
    SET_CAMERA_STATUS: (state, newStatus) => { state.isCameraEnable = newStatus; },
    SET_CAMERA_EXISTS: (state, status) => { state.isCameraExists = status; },
    SET_MICROPHONE_STATUS: (state, newStatus) => { state.isMicrophoneEnable = newStatus; },
    UPDATE_CAMERA_VIDEO_TRACK_STATUS: (state) => {
      state.localCameraStream.getVideoTracks()[0].enabled = state.isCameraEnable;
    },
    UPDATE_CAMERA_AUDIO_TRACK_STATUS: (state) => {
      state.localCameraStream.getAudioTracks()[0].enabled = state.isMicrophoneEnable;
    },
    SET_LOCAL_SCREEN_STREAM: (state, screenStream) => { state.localScreenStream = screenStream; },
    SET_USER_AUTH_TOKEN: (state, authToken) => { state.userAuthToken = authToken; },
    SET_USER_DATA: (state, userData) => { state.userData = userData; },
    TOGGLE_CONTEXT_MENU: (state, status) => { state.contextMenuIsShown = status; },
    TOGGLE_REPORT_VIOLATION_POPUP_VISIBILITY: (state, status) => {
      state.reportViolationPopupIsShown = status;
    },
    SETUP_LESSON_ID: (state, lessonId) => { state.lessonId = lessonId; },
    SETUP_LESSON_MEMBERS: (state, members) => { state.lessonMembers = members; },
    SETUP_VIOLATIONS_LIST: (state, violationsList) => { state.violationsList = violationsList; },
    TOGGLE_REPORT_BUG_POPUP_VISIBILITY: (state, status) => {
      state.reportBugPopupIsShown = status;
    },
    SET_ROOM_TYPE: (state, roomType) => { state.roomType = roomType; },
    SETUP_SERVICE_CALL_DATA: (state, data) => {
      state.serviceCallData = data;
    },
  },
  actions: {
    async setupRoomId({ commit }, roomId) {
      commit('SETUP_ROOM_ID', roomId);
      window.localStorage.setItem('roomId', roomId);
    },
    setRoomType({ commit }, roomType) {
      commit('SET_ROOM_TYPE', roomType);
      window.localStorage.setItem('roomType', roomType);
    },
    changeNotifyStringStatus({ commit }, newStatus) {
      commit('CHANGE_NOTIFY_STRING_STATUS', newStatus);
    },
    setLocalCameraStream({ commit }, stream) {
      commit('SET_LOCAL_CAMERA_STREAM', stream);
    },
    toggleCameraStatus({ state, commit }) {
      commit('SET_CAMERA_STATUS', !state.isCameraEnable);
      if (state.localCameraStream?.getVideoTracks() && state.localCameraStream.getVideoTracks()[0]) {
        commit('UPDATE_CAMERA_VIDEO_TRACK_STATUS');
      }
    },
    toggleMicrophoneStatus({ state, commit }) {
      commit('SET_MICROPHONE_STATUS', !state.isMicrophoneEnable);
      commit('UPDATE_CAMERA_AUDIO_TRACK_STATUS');
    },
    setLocalScreenStream({ commit }, stream) {
      commit('SET_LOCAL_SCREEN_STREAM', stream);
    },
    async setUserAuthToken({ commit }, authToken) {
      commit('SET_USER_AUTH_TOKEN', authToken);
      window.localStorage.setItem('authToken', authToken);
      this.$app.$axios.defaults.headers.common.Authorization = authToken;
    },
    async setupUserData({ commit, dispatch }) {
      const response = await api(this.$app.$axios).fetchUserData();
      commit('SET_USER_DATA', response.data);
      dispatch('setupLocale', response.data.locale);
      return response;
    },
    setupLocale({}, locale) {
      this.$app.$axios.defaults.headers.common['Application-Language'] = locale;
      this.$app.$i18n.locale = locale;
    },
    toggleContextMenu({ commit, state }, status) {
      commit('TOGGLE_CONTEXT_MENU', status || !state.contextMenuIsShown);
    },
    toggleReportViolationPopup({ commit, state }, status) {
      commit('TOGGLE_REPORT_VIOLATION_POPUP_VISIBILITY', status
        || !state.reportViolationPopupIsShown);
    },
    async setupLessonData(store) {
      const lessonUUID = store.state.roomId;
      if (!lessonUUID) Promise.reject();
      const response = await api(this.$app.$axios).fetchLessonData(lessonUUID);
      store.commit('SETUP_LESSON_ID', response.data.id);
      store.commit('SETUP_LESSON_MEMBERS', response.data.members);
      store.commit('lesson/SETUP_LESSON_DATE', response.data.date);
      store.commit('lesson/SETUP_LESSON_SUBJECT', response.data.subject);
      store.commit('lesson/SETUP_LESSON_TYPE', response.data.type);
      return response;
    },
    async setupServiceCallData(store) {
      try {
        const { data } = await api(this.$app.$axios).fetchServiceCallData(store.state.roomId);
        store.commit('SETUP_SERVICE_CALL_DATA', data);
      } catch (e) {
        if (ENV === 'dev') console.log(e);
      }
    },
    async sendRecommendationToStudent({}, data) {
      const response = await api(this.$app.$axios).sendRecommendationToStudent(data);
      return response;
    },
    async sendReviewToTutor({}, reviewData) {
      const response = await api(this.$app.$axios).sendReviewToTutor(reviewData);
      return response;
    },
    async setViolationsList({ commit }) {
      const response = await api(this.$app.$axios).fetchViolationsList();
      commit('SETUP_VIOLATIONS_LIST', response.data);
      return response;
    },
    async makeViolationReport({}, reportData) {
      return await api(this.$app.$axios).makeViolationReport(reportData);
    },
    async toggleReportBugPopup({ commit, state }, status) {
      commit('TOGGLE_REPORT_BUG_POPUP_VISIBILITY', status
        || !state.reportBugPopupIsShown);
    },
    async sendBugReport({}, formData) {
      return await api(this.$app.$axios).sendBugReport(formData);
    },
    async signupInLesson({ state }) {
      let response;
      switch (state.userData.role) {
        case 'tutor': {
          response = await api(this.$app.$axios).signupInLessonAsTutor(
            { lesson_id: state.lessonId },
          );
          break;
        }
        case 'student': {
          response = await api(this.$app.$axios).signupInLessonAsStudent(
            { lesson_id: state.lessonId },
          );
          break;
        }
      }
      return response;
    },
    async finishCallForTutor({ state }) {
      const response = await api(this.$app.$axios).finishCallForTutor({
        lesson_id: state.lessonId,
      });
      return response;
    },
    async finishCallForStudent({ state }) {
      const response = await api(this.$app.$axios).finishCallForStudent({
        lesson_id: state.lessonId,
      });
      return response;
    },
    setIsCameraExists({ commit }, status) {
      commit('SET_CAMERA_EXISTS', status);
    },
    async checkSingleConnectionAmongOneRoom({}, data) {
      const response = await api(this.$app.$axiosSignalingServer).checkSingleConnectionAmongOneRoom(data);
      return response;
    },
    async checkSingleConnectionAmongAllRooms({}, data) {
      const response = await api(this.$app.$axiosSignalingServer).checkSingleConnectionAmongAllRooms(data);
      return response;
    },
    async signupInServiceCall({ state }) {
      const { data } = await api(this.$app.$axios).signupInServiceCall(state.roomId);
      return data;
    },
  },
  modules: {
    lesson: lessonModule,
    lessonTimer: lessonTimerModule,
    modals: modalsModule,
  },
});
