<template>
  <div class="app-wrapper">
    <router-view></router-view>
    <notifications />
  </div>
</template>

<style lang="scss">
  @import "./assets/styles/main.scss";
  body {
    margin: 0;
  }
  * {
    font-family: Roboto;
    box-sizing: border-box;
  }
</style>

<style lang="scss">
#app {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
}
</style>

<style lang="scss" scoped>
.app-wrapper {
  font-family: 'Roboto';
  height: 100%;
}
</style>
