<template>
  <div class="notify-block" :class="notifyBlockClasses">
    <div class="notify-block__image-block" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>
    <div class="notify-block__text-block">
      <div class="notify-block__title">{{ title }}</div>
      <div class="notify-block__text">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    grow: {
      type: Number,
      default: 1,
      validator(prop) {
        return [0, 1].includes(prop);
      },
    },
    type: {
      type: String,
      default: 'default',
      validator(prop) {
        return ['default', 'warn', 'success'].includes(prop);
      },
    },
  },
  computed: {
    notifyBlockClasses() {
      return [
        `color--${this.type}`,
        `border-color--${this.type}`,
        `background-color--${this.type}`,
        `grow--${this.grow}`,
      ];
    },
  },
};
</script>

<style lang="scss">
.notify-block {
  display: flex;
  padding: 10px 15px;
  border-width: 1px;
  border-style: solid;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  flex-grow: 1;

  &__image-block {
    font-size: 45px;
    line-height: 45px;
    margin-right: 10px;
  }
  &__text-block {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;

  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    a{
      color: inherit;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }
  }
  .la-close {
    color: $color-brand;
  }
}

// rest styles

.grow {
  &--1 {
    flex-grow: 1;
  }

  &--0 {
    flex-grow: 0;
  }
}

.color {
  &--default {
    color: $color-brand;
  }
  &--warn {
    color: $color-accent;

    .la-close{
      color: $color-accent;
    }
  }
  &--success {
    color: $color-success;

    .la-close{
      color: $color-success;
    }
  }
}

.border-color {
  &--default {
    border-color: $color-brand;
  }
  &--warn {
    border-color: $color-accent;
  }
  &--success {
    border-color: $color-success;
  }
}

.background-color {
  &--default {
    background-color: $color-brand-opacity;
  }
  &--warn {
    background-color: $color-accent-opacity;
  }
  &--success {
    background-color: $color-success-opacity;
  }
}
</style>
