<template>
  <div class="popupContainer" v-if="isPopupVisible">
    <div class="popup">
      <div class="popup__title">{{ this.$t('continueLessonPopup.title') }}</div>
      <div class="popup__text">{{ this.$t('continueLessonPopup.text') }}</div>
      <div class="popup__btn-container">
        <BaseButton class="popup__btn" @click="changeStatus(true)">
          {{ this.$t('continueLessonPopup.yes') }}
        </BaseButton>
        <BaseButton class="popup__btn" :theme="'white-accent'" @click="changeStatus(false)">
          {{ this.$t('continueLessonPopup.no') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import { mapGetters } from 'vuex';
import { LESSON_DURATION_SECONDS } from '@/helpers/variables';

const SHOW_CONTINUE_LESSON_POPUP_POPUP_ON_MIN = 2;

export default {
  props: {
    isTutorAgreeToContinueLesson: {
      type: [Boolean],
      default: null,
    },
  },
  emits: [
    'changeContinueLessonStatusHandler',
  ],
  components: {
    BaseButton,
  },
  data() {
    return {
      isShowPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      userData: 'getUserData',
      lessonFinishDate: 'lessonTimer/lessonFinishDate',
      currentDate: 'lessonTimer/currentDate',
      lessonDuration: 'lessonTimer/lessonDuration',

    }),
    isPopupVisible() {
      return (
        this.userData?.role === 'tutor'
        && this.isTutorAgreeToContinueLesson === null
        && this.isShowPopup
      );
    },
  },
  mounted() {
    if (this.currentDate.isBefore(this.lessonFinishDate)) {
      const secToFinishLesson = LESSON_DURATION_SECONDS - this.lessonDuration;
      const msToShowPopup = (secToFinishLesson - (SHOW_CONTINUE_LESSON_POPUP_POPUP_ON_MIN * 60)) * 1000;
      setTimeout(() => { this.isShowPopup = true; }, msToShowPopup);
    }
  },
  methods: {
    changeStatus(status) {
      this.$emit('changeContinueLessonStatusHandler', status);
    },
  },
};
</script>

<style lang="scss" scoped>
.popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 200;
}
.popup {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  max-width: 500px;
  border: 2px solid $color-border;
  text-align: center;

  &__title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 15px;
  }

  &__btn-container {
    display: flex;
    justify-content: center;
  }
  &__btn {
    width: 100px;
    &:last-child {
      margin-left: 10px;
    }
  }
}
</style>
