<template>
  <div class="badge">
    <slot name="default"/>
  </div>
</template>

<script>
export default {
  name: 'IconBadge',
};
</script>

<style scoped lang="scss">
.badge {
  background: $color-accent;
  color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
