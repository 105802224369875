<template>
  <div v-if="isShown" class="overflow" @click="$emit('onCloseHandler')"></div>
  <div v-if="isShown" class="context-menu">
    <div
      class="context-menu__item"
      @click="openBugPopup(true)"
    >{{ $t('callPage.reportAProblem') }}</div>
    <div
      class="context-menu__item"
      @click="openViolationPopup(true)"
    >{{ $t('callPage.reportAViolation') }}</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  emits: ['onCloseHandler'],
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      toggleReportViolationPopup: 'toggleReportViolationPopup',
      toggleContextMenu: 'toggleContextMenu',
      toggleReportBugPopup: 'toggleReportBugPopup',
    }),
    openViolationPopup() {
      this.toggleReportViolationPopup(true);
      this.toggleContextMenu(false);
    },
    openBugPopup() {
      this.toggleReportBugPopup(true);
      this.toggleContextMenu(false);
    },
  },
};
</script>

<style scoped lang="scss">
.overflow {
  position: fixed;
  top: 0;
  left: 0;
  //background: rgba(0,0,0,.25);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
.context-menu {
  position: fixed;
  min-width: 230px;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #fff;
  padding: 10px;
  border-radius: 12px;
  border: 2px solid $color-border;
  z-index: 1000;

  &__item {
    color: $color-text;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      background: $color-accent;
      color: #ffffff;
    }
  }
}
</style>
