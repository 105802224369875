<template>
  <div class="progress-ring">
    <svg class="progress-ring__svg" :width="width" :height="height">
      <circle
        class="progress-ring__circle-bottom"
        stroke="#ccc"
        :stroke-width="strokeWidth"
        :cx="cx"
        :cy="cy"
        :r="r"
        fill='transparent'
      ></circle>
      <circle
        class="progress-ring__circle-top"
        :stroke-width="strokeWidth"
        :cx="cx"
        :cy="cy"
        :r="r"
        fill='transparent'
        :style="{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: computedProgress
        }"
      ></circle>
    </svg>
    <div class="progress-ring__inner-container">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    radius: {
      type: Number,
      default: 30,
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    width() {
      return this.radius * 2;
    },
    height() {
      return this.radius * 2;
    },
    cx() {
      return this.radius;
    },
    cy() {
      return this.radius;
    },
    r() {
      return this.radius - (this.strokeWidth * 2);
    },
    circumference() {
      return 2 * Math.PI * this.r;
    },
    computedProgress() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
  },
};
</script>

<style scoped lang="scss">
.progress-ring {
  position: relative;
  &__circle-bottom {
    stroke: #e0e0e0
  }
  &__circle-top{
    transform-origin: center;
    transform: rotate(-90deg);
    stroke: $color-accent;
    transition: strokeDashoffset linear 1s;
  }
  &__inner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
