import { createI18n } from 'vue-i18n';

import uk from '@/locales/uk.json';
import en from '@/locales/en.json';
import ru from '@/locales/ru.json';
import pl from '@/locales/pl.json';

export default createI18n({
  locale: 'en',
  fallbackLocale: 'uk',
  messages: {
    en,
    uk,
    ru,
    pl,
  },
});
