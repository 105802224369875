<template>
  <div class="call">
    <!--  DEBUG  -->
    <!--        roomId: {{ roomId }}<br>-->
    <!--        currentDate: {{currentDate}}<br>-->
    <!--        lessonStartDate: {{lessonStartDate}} | lessonFinishDate: {{lessonFinishDate}}<br>-->
    <!--        getFormattedLessonDuration: {{getFormattedLessonDuration}}<br>-->
    <!--        isMembersFromOppositeSide: {{isMembersFromOppositeSide}}<br>-->
    <!--        peers: {{peers}}-->
    <!--        peerIdPrimaryScreen: {{peerIdPrimaryScreen}}-->
    <!--        sound emitter: {{soundEmittedFromPeer}}-->
    <!--        isTutorAgreeToContinueLesson: {{ isTutorAgreeToContinueLesson }}<br>-->
    <!--  DEBUG  -->
    <HeaderCallRoom>
      <template #right>
        <template v-if="isShowСountdownTimerOn2MinBeforeLessonEnd">
          <ProgressRing
            :radius="20"
            :progress="percentageOf2MinutesCountDownTimer"
          ></ProgressRing>
          {{ getFormattedCountdownTime }}
        </template>
        <template v-if="!isLessonStarted && !isLessonFinished">
          {{ $t('callPage.waiting') }}: {{ getFormattedLessonDuration }}
        </template>
      </template>
    </HeaderCallRoom>
    <div class="call__content">
      <div
        class="call__videos-container"
        :class="{
          'screens-count-2': totalCountOfScreens === 2,
          'screens-count-2-plus': totalCountOfScreens > 2,
          'is-user-right': peerIdPrimaryScreen
        }"
      >
        <div
          v-if="myPeer && localCameraStream"
          class="call__video-wrapper"
          :class="{
            'is-fullscreen': peerIdPrimaryScreen === myPeer.id
          }"
          :id="myPeer.id"
        >
          <video
            class="call__video"
            :srcObject.prop="localCameraStream"
            ref="myMediaStream"
            autoplay
            muted
          ></video>
          <div v-if="!isCameraEnable" class="call__avatar-container">
            <img class="call__avatar" :src="userData.avatar" alt="avatar"/>
          </div>
          <div
            v-if="soundEmittedFromPeer === myPeer.id"
            class="call__voice-border"
          ></div>
          <BottomVideoCardBar
            class="call__bottom-bar"
            :peerId="myPeer.id"
            :isTutor="userData.role === 'tutor'"
            :mainBadgeText="$t('callPage.you')"
            :isMicrophoneEnable="!this.isMicrophoneEnable"
            :needToShowMicrophoneBadge="true"
            :isPrimaryScreen="peerIdPrimaryScreen === myPeer.id"
            @makePrimaryVideo="makePrimaryVideo"
          />
        </div>
        <div
          v-if="myScreenSharePeer && localScreenStream"
          class="call__video-wrapper"
          :class="{
            'is-fullscreen': peerIdPrimaryScreen === myScreenSharePeer.id
          }"
          :id="myScreenSharePeer.id"
        >
          <video
            class="call__video"
            :srcObject.prop="localScreenStream"
            ref="myMediaStream"
            autoplay
            muted
          ></video>
          <BottomVideoCardBar
            class="call__bottom-bar"
            :peerId="myScreenSharePeer.id"
            :isTutor="userData.role === 'tutor'"
            :mainBadgeText="$t('callPage.youScreenSharing')"
            :isMicrophoneEnable="!this.isMicrophoneEnable"
            :needToShowMicrophoneBadge="false"
            :isPrimaryScreen="peerIdPrimaryScreen === myScreenSharePeer.id"
            @makePrimaryVideo="makePrimaryVideo"
          />
        </div>
        <div
          v-for="(peer, key) in peers"
          class="call__video-wrapper"
          :class="{
            'is-fullscreen': peerIdPrimaryScreen === key
          }"
          :key="key"
          :id="key"
        >
          <video
            class="call__video"
            :srcObject.prop="peer.stream"
            autoplay
          ></video>
          <div
            v-if="!peer.isCameraEnable && !peer.isScreenSharingStream"
            class="call__avatar-container"
          >
            <img class="call__avatar" :src="peer.userAvatar" alt="avatar"/>
          </div>
          <div
            v-if="soundEmittedFromPeer === key"
            class="call__voice-border"
          ></div>
            <BottomVideoCardBar
              class="call__bottom-bar"
              :peerId="key"
              :isTutor="peer.isTutor"
              :mainBadgeText="`${peer.userName} ${peer.isScreenSharingStream ? '(демонстрация экрана)' : ''}`"
              :isMicrophoneEnable="peer && !peer.isMicrophoneEnable && !peer.isScreenSharingStream"
              :needToShowMicrophoneBadge="true"
              :isPrimaryScreen="peerIdPrimaryScreen === key"
              @makePrimaryVideo="makePrimaryVideo"
            />
        </div>
      </div>
    </div>
    <FooterCallRoom>
      <template #center>
        <base-button
          :theme="isCameraEnable ? 'white' : 'white-accent'"
          :disabled="!isCameraExists"
          @click="toggleCamera"
        >
          <i v-if="isCameraEnable" class="la la-video button-icon button__icon"></i>
          <i v-else class="la la-video-slash button-icon button__icon"></i>
        </base-button>
        <base-button
          :theme="isMicrophoneEnable ? 'white' : 'white-accent'"
          @click="toggleMicrophone"
          class="margin-left-10"
        >
          <i v-if="isMicrophoneEnable" class="las la-microphone button-icon button__icon"></i>
          <i v-else class="las la-microphone-slash button-icon button__icon"></i>
        </base-button>
        <base-button
          :theme="localScreenStream ? 'white-accent' : 'white'"
          class="margin-left-10"
          @click="toggleScreenStream"
        >
          <i class="las la-desktop button-icon button__icon"></i>
        </base-button>
        <base-button
          class="context-menu-icon margin-left-10"
          :theme="'white'"
          @click="toggleCtxMenu"
        >
          <i class="las la-ellipsis-v button-icon button__icon"></i>
        </base-button>
        <base-button
          class="margin-left-10"
          :theme="'default'"
          @click="leaveOrFinishLessonBtnHandler"
        >
          <i class="las la-phone-alt button-icon button__icon"></i>
        </base-button>
      </template>
      <template #right>
        <template v-if="isShowСountdownTimerOn2MinBeforeLessonEnd">
          <ProgressRing
            :radius="20"
            :progress="percentageOf2MinutesCountDownTimer"
          ></ProgressRing>
          {{ getFormattedCountdownTime }}
        </template>
        <template v-if="!isLessonStarted && !isLessonFinished">
          {{ $t('callPage.waiting') }}: {{ getFormattedLessonDuration }}
        </template>
      </template>
    </FooterCallRoom>
    <ContextMenu
      :isShown="contextMenuIsShown"
      @onCloseHandler="toggleCtxMenu"
    />
    <ReportViolationPopup
      :isShown="reportViolationPopupIsShown"
      @closeHandler="toggleReportViolationPopup"
    />
    <ReportBugPopup
      :isShown="reportBugPopupIsShown"
      @closeHandler="toggleReportBugPopup"
    />
    <TimerPopup
      v-if="isShowWaitMembersPopup && !isMembersFromOppositeSide && !isForceCloseWaitMembersPopup"
      @closeHandler="forceCloseWaitMembersPopup"
    />
    <ContinueLessonPopup
      v-if="lessonStartDate && lessonFinishDate && lessonDuration"
      :isTutorAgreeToContinueLesson="isTutorAgreeToContinueLesson"
      @changeContinueLessonStatusHandler="changeContinueLessonStatusHandler"
    ></ContinueLessonPopup>
    <ConnectionPopup v-if="isConnectionPopupShown"/>
    <ActionsPanel/>
    <TutorLeaveLessonModal
      v-if="userData?.role === 'tutor' && tutorFinishLessonModal"
      @closeModal="changeTutorFinishLessonModalStatus(false)"
      @finishLesson="finishLesson"
      @leaveLesson="leaveLesson"
    />
  </div>
</template>

<script>

import Peer from 'peerjs-with-transceiver';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
import ContextMenu from '@/components/ContextMenu.vue';
import ReportViolationPopup from '@/components/ReportViolationPopup.vue';
import ReportBugPopup from '@/components/ReportBugPopup.vue';
import ContinueLessonPopup from '@/components/ContinueLessonPopup.vue';
import TimerPopup from '@/components/TimerPopup.vue';
import ProgressRing from '@/components/ProgressRing.vue';
import CallPageMixin from '@/mixins/CallPageMixin';
import TutorLeaveLessonModal from '@/components/TutorLeaveLessonModal.vue';

import {
  HIDE_TIMER_WAIT_MEMBERS_POPUP_ON_SEC,
  MIN_DISCONNECT_TIME_ON_SEC,
  LESSON_DURATION_SECONDS,
} from '@/helpers/variables';

import sound from '../assets/sounds/connect_to_lesson.wav';

const {
  VUE_APP_ENVIRONMENT: ENV,
  VUE_APP_ENABLE_VIDEO_CHAT_RECONNECT: IS_RECONNECT_ENABLED,
  VUE_APP_TEME_URL: TEME_URL,
} = process.env;

export default {
  mixins: [CallPageMixin],
  emits: ['closeHandler'],
  components: {
    ContextMenu,
    ReportViolationPopup,
    ReportBugPopup,
    TimerPopup,
    ProgressRing,
    ContinueLessonPopup,
    TutorLeaveLessonModal,
  },
  data: () => ({
    timerId: null,
    finishLessonTimerId: null,
    notifySound2MinTimerId: null,
    isForceCloseWaitMembersPopup: false,
    isTutorAgreeToContinueLesson: null,
  }),
  async mounted() {
    if (!window.localStorage.getItem('roomId')) {
      if (window.localStorage.getItem('redirectIfPressBack')) {
        await this.$router.push(window.localStorage.getItem('redirectIfPressBack'));
        return;
      }
    }

    if (!this.localCameraStream) this.$router.push('/preview');
    this.notifySound = new Audio(sound);
    try {
      await this.notifySound?.play();
    } catch (e) {
      if (ENV === 'dev') {
        console.error(e);
      }
    }

    setTimeout(() => { this.playSoundOnNewUser = true; }, 1000);

    await this.setupLessonData();
    this.setupLessonTimers();

    const peerUuid = uuidv4();
    this.myPeer = new Peer(peerUuid);
    if (IS_RECONNECT_ENABLED === 'true') {
      this.myPeer.on('disconnected', () => this.myPeer.reconnect());
    }

    this.setupSocketIOHandlers();

    this.myPeer.on('call', this.acceptCallHandler);
    this.myPeer.on('open', this.openPeerHandler);
    this.myPeer.on('close', this.closePeerHandler);

    this.startCaptureVoiceEmitting();
  },
  unmounted() {
    window.clearTimeout(this.timerId);
  },
  methods: {
    ...mapActions({
      toggleContextMenu: 'toggleContextMenu',
      toggleReportViolationPopup: 'toggleReportViolationPopup',
      toggleReportBugPopup: 'toggleReportBugPopup',
      signupInLesson: 'signupInLesson',
      finishCallForTutor: 'finishCallForTutor',
      finishCallForStudent: 'finishCallForStudent',
      setupLessonStartDate: 'lessonTimer/setupLessonStartDate',
      setupLessonFinishDate: 'lessonTimer/setupLessonFinishDate',
      setupTimerPopupVisibilityTimeRange: 'lessonTimer/setupTimerPopupVisibilityTimeRange',
      setupCurrentDate: 'lessonTimer/setupCurrentDate',
      changeTutorFinishLessonModalStatus: 'modals/changeTutorFinishLessonModalStatus',
    }),

    setupSocketIOHandlers() {
      this.$socket.on('USER_JOINED', this.newUserJoinedHandler);
      this.$socket.on('USER_DISCONNECTED', this.userDisconnectedHandler);
      this.$socket.on('SET_PEER_DATA', this.setPeerData);
      this.$socket.on('USER_CHANGED_MICROPHONE_STATUS', this.updateRemoteUserMicStatus);
      this.$socket.on('USER_CHANGED_CAMERA_STATUS', this.updateRemoteUserCamStatus);
      this.$socket.on('USER_STARTED_SCREEN_SHARING', this.makePeerIdScreenAsPrimary);
      this.$socket.on('PEER_STARTED_SCREEN_SHARING', this.onRemotePeerScreenSharing);
      this.$socket.on('TUTOR_FINISHED_LESSON', this.onTutorFinishedLesson);
      this.$socket.on('USER_EMITTED_SOUND', this.onUserEmitSound);
      this.$socket.on('SET_CONTINUE_LESSON_STATUS', this.setContinueLessonStatus);
      this.$socket.on('IS_NEW_MESSAGE', this.newMessageNotify);
      if (IS_RECONNECT_ENABLED === 'true') {
        this.$socket.on('connect', this.socketConnectHandler);
        this.$socket.on('disconnect', this.socketDisconnectHandler);
      }
    },
    setupLessonTimers() {
      this.setupLessonStartDate();
      this.setupLessonFinishDate();
      this.setupTimerPopupVisibilityTimeRange();
      this.setupCurrentDate();
      this.timerId = setInterval(this.setupCurrentDate, 1000);

      // end a user call if it does not exist at least one participant from the opposite side
      if (this.currentDate.isBefore(this.hideTimerPopupDate)) {
        const secToDisconnectIfNoMembersOnOtherSide = HIDE_TIMER_WAIT_MEMBERS_POPUP_ON_SEC - this.lessonDuration;
        const safeTimeMs = 1500; // needs to avoid timers out of sync
        setTimeout(() => {
          if (!this.isMembersFromOppositeSide) {
            this.finishLesson();
            if (this.userData.role === 'tutor') {
              if (this.getLessonMembersFromOppositeSide.length === 1) {
                this.$notify({ type: 'error', title: this.$t('callPage.notify.studentDidNotComeToClass') });
              }
              if (this.getLessonMembersFromOppositeSide.length > 1) {
                this.$notify({ type: 'error', title: this.$t('callPage.notify.studentsDidNotComeToClass') });
              }
            } else {
              this.$notify({ type: 'error', title: this.$t('callPage.notify.tutorDidNotComeToClass') });
            }
          }
        }, secToDisconnectIfNoMembersOnOtherSide * 1000 + safeTimeMs);
      }

      // finish lesson if 60 minutes ends
      if (this.currentDate.isBefore(this.lessonFinishDate)) {
        const secToFinishLesson = LESSON_DURATION_SECONDS - this.lessonDuration;
        this.finishLessonTimerId = setTimeout(() => {
          this.finishLesson();
          this.$notify({ type: 'success', title: this.$t('callPage.notify.lessonCompleted') });
        }, secToFinishLesson * 1000);
      }

      // send notify msg about 10 minutes before lesson end
      if (this.currentDate.isBefore(this.lessonFinishDate)) {
        const secToFinishLesson = LESSON_DURATION_SECONDS - this.lessonDuration;
        const secToShowNotify = secToFinishLesson - (10 * 60);
        const msToShowNotify = secToShowNotify * 1000;
        if (msToShowNotify > 0) {
          setTimeout(() => {
            this.$notify({ type: 'success', title: this.$t('callPage.notify.lessonWillFinishIn10Min') });
          }, msToShowNotify);
        }
      }

      // play the sound 10 minutes before the end of the lesson
      if (this.currentDate.isBefore(this.lessonFinishDate)) {
        const secToFinishLesson = LESSON_DURATION_SECONDS - this.lessonDuration;
        const msToPlaySound = (secToFinishLesson - (10 * 60)) * 1000;
        if (msToPlaySound > 0) {
          setTimeout(() => {
            this.notifySound.play();
          }, msToPlaySound);
        }
      }

      // play the sound 2 minutes before the end of the lesson
      if (this.currentDate.isBefore(this.lessonFinishDate)) {
        const secToFinishLesson = LESSON_DURATION_SECONDS - this.lessonDuration;
        const msToPlaySound = (secToFinishLesson - (2 * 60)) * 1000;
        if (msToPlaySound > 0) {
          this.notifySound2MinTimerId = setTimeout(() => {
            this.notifySound.play();
          }, msToPlaySound);
        }
      }
    },
    toggleCtxMenu() {
      this.toggleContextMenu();
    },
    async finishLesson() {
      await this.setupLessonData();

      if (this.userData.role === 'tutor') {
        try {
          await this.finishCallForTutor();
        } catch (e) {
          if (ENV === 'dev') {
            console.error('Failed to finish lesson');
            console.error(e);
          }
        }
        this.$socket.emit('TUTOR_FINISHED_LESSON', {
          roomId: this.roomId,
          peerId: this.myPeer.id,
        });
      } else if (this.userData.role === 'student') {
        try {
          await this.finishCallForStudent();
        } catch (e) {
          if (ENV === 'dev') {
            console.error('Failed to finish lesson');
            console.error(e);
          }
        }
      }

      this.$socket.off('disconnect', this.socketDisconnectHandler);

      this.myPeer.destroy();
      if (this.userData.role === 'student') {
        this.$router.push('/leave-call-student');
      } else if (this.userData.role === 'tutor') {
        this.$router.push('/leave-call-tutor');
      }
      this.myPeer = null;
    },
    leaveLesson() {
      window.location = TEME_URL;
    },
    onTutorFinishedLesson() {
      // this.$notify({ type: 'success', title: this.$t('callPage.notify.tutorFinishedLesson') });
      this.finishLesson();
    },
    forceCloseWaitMembersPopup() {
      this.isForceCloseWaitMembersPopup = true;
    },
    setContinueLessonStatus(status) {
      this.isTutorAgreeToContinueLesson = status;

      if (status) {
        this.removeFinishLessonTimer();
        this.removeNotifySoundOn2MinBeforeLessonEnd();
        if (this.userData.role === 'tutor') {
          this.$notify({ type: 'success', title: this.$t('callPage.notify.theLessonWasExtended') });
        }
        if (this.userData.role === 'student') {
          this.$notify({ type: 'success', title: this.$t('callPage.notify.theTutorExtendedTheLesson') });
        }
      }
    },
    changeContinueLessonStatusHandler(status) {
      this.isTutorAgreeToContinueLesson = status;
      this.$socket.emit('TUTOR_CHANGED_CONTINUE_LESSON_STATUS', {
        roomId: this.roomId,
        peerId: this.myPeer.id,
        continueLessonStatus: status,
      });

      if (status) {
        this.removeFinishLessonTimer();
        this.removeNotifySoundOn2MinBeforeLessonEnd();
        this.$notify({ type: 'success', title: this.$t('callPage.notify.youExtendedTheLesson') });
      }
    },
    removeFinishLessonTimer() {
      if (this.finishLessonTimerId) {
        clearTimeout(this.finishLessonTimerId);
        this.finishLessonTimerId = null;
      }
    },
    removeNotifySoundOn2MinBeforeLessonEnd() {
      if (this.notifySound2MinTimerId) {
        clearTimeout(this.notifySound2MinTimerId);
        this.notifySound2MinTimerId = null;
      }
    },
    leaveOrFinishLessonBtnHandler() {
      const minDisconnectTime = moment(this.lessonStartDate).add(MIN_DISCONNECT_TIME_ON_SEC, 'seconds');
      if (this.currentDate.isBefore(minDisconnectTime)) {
        this.$notify({ type: 'error', title: this.$t('callPage.notify.cantLeaveLesson') });
        return;
      }

      if (this.userData.role === 'tutor') {
        this.changeTutorFinishLessonModalStatus(true);
      } else if (this.userData.role === 'student') {
        this.finishLesson();
      }
    },
  },
  computed: {
    ...mapGetters({
      contextMenuIsShown: 'getContextMenuIsShown',
      reportViolationPopupIsShown: 'getReportViolationPopupIsShown',
      reportBugPopupIsShown: 'getReportBugPopupIsShown',
      lessonDuration: 'lessonTimer/lessonDuration',
      getFormattedLessonDuration: 'lessonTimer/getFormattedLessonDuration',
      isLessonStarted: 'lessonTimer/isLessonStarted',
      getFormattedCountdownTime: 'lessonTimer/getFormattedCountdownTime',
      isLessonFinished: 'lessonTimer/isLessonFinished',
      isMembersFromOppositeSide: 'isMembersFromOppositeSide',
      is2MinutesBeforeTheLessonEnd: 'lessonTimer/is2MinutesBeforeTheLessonEnd',
      percentageOf2MinutesCountDownTimer: 'lessonTimer/percentageOf2MinutesCountDownTimer',
      lessonMembersFromOppositeSide: 'getMembersFromOppositeSide',
      tutorFinishLessonModal: 'modals/tutorFinishLessonModal',

      // удалить что не нужно
      lessonStartDate: 'lessonTimer/lessonStartDate',
      lessonFinishDate: 'lessonTimer/lessonFinishDate',
      showTimerPopupDate: 'lessonTimer/showTimerPopupDate',
      hideTimerPopupDate: 'lessonTimer/hideTimerPopupDate',
      currentDate: 'lessonTimer/currentDate',
    }),
    isShowWaitMembersPopup() {
      if (
        !this.currentDate
        || !this.showTimerPopupDate
        || !this.hideTimerPopupDate
      ) {
        return false;
      }
      return this.currentDate.isBetween(
        this.showTimerPopupDate,
        this.hideTimerPopupDate,
      );
    },
    getLessonMembersFromOppositeSide() {
      const members = [];
      this.lessonMembersFromOppositeSide.forEach((member) => {
        if (!members.find((m) => m.id === member.id)) {
          members.push(member);
        }
      });
      return members;
    },
    isShowСountdownTimerOn2MinBeforeLessonEnd() {
      return (this.is2MinutesBeforeTheLessonEnd && !this.isTutorAgreeToContinueLesson);
    },
  },
  watch: {
    reportViolationPopupIsShown(newVal) {
      if (newVal) this.setupLessonData();
    },
    isShowWaitMembersPopup(newVal) {
      if (newVal && !this.isMembersFromOppositeSide) {
        this.notifySound.play();
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/styles/CallPage.scss"></style>
