<template>
  <div class="call">
    <!--  DEBUG  -->
    <!--        roomId: {{ roomId }}<br>-->
    <!--        peers: {{peers}}-->
    <!--        peerIdPrimaryScreen: {{peerIdPrimaryScreen}}-->
    <!--        sound emitter: {{soundEmittedFromPeer}}-->
    <!--  DEBUG  -->
    <HeaderCallRoom/>
    <div class="call__content">
      <div
        class="call__videos-container"
        :class="{
          'screens-count-2': totalCountOfScreens === 2,
          'screens-count-2-plus': totalCountOfScreens > 2,
          'is-user-right': peerIdPrimaryScreen
        }"
      >
        <div
          v-if="myPeer && localCameraStream"
          class="call__video-wrapper"
          :class="{
            'is-fullscreen': peerIdPrimaryScreen === myPeer.id
          }"
          :id="myPeer.id"
        >
          <video
            class="call__video"
            :srcObject.prop="localCameraStream"
            ref="myMediaStream"
            autoplay
            muted
          ></video>
          <div v-if="!isCameraEnable" class="call__avatar-container">
            <img class="call__avatar" :src="userData.avatar" alt="avatar"/>
          </div>
          <div
            v-if="soundEmittedFromPeer === myPeer.id"
            class="call__voice-border"
          ></div>
          <BottomVideoCardBar
            class="call__bottom-bar"
            :peerId="myPeer.id"
            :isTutor="userData.role === 'tutor'"
            :mainBadgeText="$t('callPage.you')"
            :isMicrophoneEnable="!this.isMicrophoneEnable"
            :needToShowMicrophoneBadge="true"
            :isPrimaryScreen="peerIdPrimaryScreen === myPeer.id"
            @makePrimaryVideo="makePrimaryVideo"
          />
        </div>
        <div
          v-if="myScreenSharePeer && localScreenStream"
          class="call__video-wrapper"
          :class="{
            'is-fullscreen': peerIdPrimaryScreen === myScreenSharePeer.id
          }"
          :id="myScreenSharePeer.id"
        >
          <video
            class="call__video"
            :srcObject.prop="localScreenStream"
            ref="myMediaStream"
            autoplay
            muted
          ></video>
          <BottomVideoCardBar
            class="call__bottom-bar"
            :peerId="myScreenSharePeer.id"
            :isTutor="userData.role === 'tutor'"
            :mainBadgeText="$t('callPage.youScreenSharing')"
            :isMicrophoneEnable="!this.isMicrophoneEnable"
            :needToShowMicrophoneBadge="false"
            :isPrimaryScreen="peerIdPrimaryScreen === myScreenSharePeer.id"
            @makePrimaryVideo="makePrimaryVideo"
          />
        </div>
        <div
          v-for="(peer, key) in peers"
          class="call__video-wrapper"
          :class="{
            'is-fullscreen': peerIdPrimaryScreen === key
          }"
          :key="key"
          :id="key"
        >
          <video
            class="call__video"
            :srcObject.prop="peer.stream"
            autoplay
          ></video>
          <div
            v-if="!peer.isCameraEnable && !peer.isScreenSharingStream"
            class="call__avatar-container"
          >
            <img class="call__avatar" :src="peer.userAvatar" alt="avatar"/>
          </div>
          <div
            v-if="soundEmittedFromPeer === key"
            class="call__voice-border"
          ></div>
          <BottomVideoCardBar
            class="call__bottom-bar"
            :peerId="key"
            :isTutor="peer.isTutor"
            :mainBadgeText="`${peer.userName} ${peer.isScreenSharingStream ? '(демонстрация экрана)' : ''}`"
            :isMicrophoneEnable="peer && !peer.isMicrophoneEnable && !peer.isScreenSharingStream"
            :needToShowMicrophoneBadge="true"
            :isPrimaryScreen="peerIdPrimaryScreen === key"
            @makePrimaryVideo="makePrimaryVideo"
          />
        </div>
      </div>
    </div>
    <FooterCallRoom>
      <template #center>
        <base-button
          :theme="isCameraEnable ? 'white' : 'white-accent'"
          :disabled="!isCameraExists"
          @click="toggleCamera"
        >
          <i v-if="isCameraEnable" class="la la-video button-icon button__icon"></i>
          <i v-else class="la la-video-slash button-icon button__icon"></i>
        </base-button>
        <base-button
          :theme="isMicrophoneEnable ? 'white' : 'white-accent'"
          @click="toggleMicrophone"
          class="margin-left-10"
        >
          <i v-if="isMicrophoneEnable" class="las la-microphone button-icon button__icon"></i>
          <i v-else class="las la-microphone-slash button-icon button__icon"></i>
        </base-button>
        <base-button
          :theme="localScreenStream ? 'white-accent' : 'white'"
          class="margin-left-10"
          @click="toggleScreenStream"
        >
          <i class="las la-desktop button-icon button__icon"></i>
        </base-button>
        <base-button :theme="'default'" class="margin-left-10" @click="finishLesson">
          <i class="las la-phone-alt button-icon button__icon"></i>
        </base-button>
      </template>
    </FooterCallRoom>
    <ConnectionPopup v-if="isConnectionPopupShown"/>
    <ActionsPanel
      :needToShowGameBtn="false"
      :needToShowDrawingBoardBtn="false"
    />
  </div>
</template>

<script>

import Peer from 'peerjs-with-transceiver';
import { v4 as uuidv4 } from 'uuid';
import CallPageMixin from '@/mixins/CallPageMixin';

import {
} from '@/helpers/variables';

import sound from '../assets/sounds/connect_to_lesson.wav';

const {
  VUE_APP_ENVIRONMENT: ENV,
  VUE_APP_ENABLE_VIDEO_CHAT_RECONNECT: IS_RECONNECT_ENABLED,
  VUE_APP_TEME_URL: TEME_URL,
} = process.env;

export default {
  mixins: [CallPageMixin],
  emits: ['closeHandler'],
  async mounted() {
    if (!this.localCameraStream) this.$router.push('/preview');
    this.notifySound = new Audio(sound);
    try {
      await this.notifySound?.play();
    } catch (e) {
      if (ENV === 'dev') {
        console.error(e);
      }
    }

    setTimeout(() => { this.playSoundOnNewUser = true; }, 1000);

    // await this.setupLessonData();

    const peerUuid = uuidv4();
    this.myPeer = new Peer(peerUuid);
    if (IS_RECONNECT_ENABLED === 'true') {
      this.myPeer.on('disconnected', () => this.myPeer.reconnect());
    }

    this.setupSocketIOHandlers();

    this.myPeer.on('open', this.openPeerHandler);
    this.myPeer.on('call', this.acceptCallHandler);
    this.myPeer.on('close', this.closePeerHandler);

    this.startCaptureVoiceEmitting();
  },
  methods: {
    setupSocketIOHandlers() {
      this.$socket.on('USER_JOINED', this.newUserJoinedHandler);
      this.$socket.on('USER_DISCONNECTED', this.userDisconnectedHandler);
      this.$socket.on('SET_PEER_DATA', this.setPeerData);
      this.$socket.on('USER_CHANGED_MICROPHONE_STATUS', this.updateRemoteUserMicStatus);
      this.$socket.on('USER_CHANGED_CAMERA_STATUS', this.updateRemoteUserCamStatus);
      this.$socket.on('USER_STARTED_SCREEN_SHARING', this.makePeerIdScreenAsPrimary);
      this.$socket.on('PEER_STARTED_SCREEN_SHARING', this.onRemotePeerScreenSharing);
      this.$socket.on('USER_EMITTED_SOUND', this.onUserEmitSound);
      this.$socket.on('IS_NEW_MESSAGE', this.newMessageNotify);
      if (IS_RECONNECT_ENABLED === 'true') {
        this.$socket.on('connect', this.socketConnectHandler);
        this.$socket.on('disconnect', this.socketDisconnectHandler);
      }
    },
    async finishLesson() {
      this.$socket.off('disconnect', this.socketDisconnectHandler);
      this.myPeer.destroy();
      this.myPeer = null;
      window.location = TEME_URL;
    },
  },
};
</script>

<style scoped lang="scss" src="@/styles/CallPage.scss"></style>
