<template>
  <div v-if="isShowLessonData" class="notify-container">
    <NotifyBlock class="notify">
      <div class="notify__content">
        <div class="notify__row">
          <span class="notify__text-bold">{{ $t('lessonDataPreview.subject') }}:</span> {{lessonSubject}}
        </div>
        <div class="notify__row">
          <span class="notify__text-bold">{{ $t('lessonDataPreview.lessonType') }}:</span> {{lessonType}}
        </div>
        <div class="notify__row">
          <span class="notify__text-bold">
            {{ $t('lessonDataPreview.duration') }}:</span>
            60 {{ $t('lessonDataPreview.min') }}
        </div>
        <div class="notify__row">
          <span class="notify__text-bold">{{ $t('lessonDataPreview.start') }}:</span> {{formattedLessonDate}}
        </div>
        <div class="notify__members-container">
          <div
            v-for="lessonMember of getFilteredMembers"
            :key="lessonMember.id"
            class=" tooltip"
            :data-tooltip-text="getFormattedUserName({
                firstname: lessonMember.firstname,
                lastname: lessonMember.lastname
              })"
          >
            <div
              class="notify__members-avatar-container"
              :class="{'notify__members-avatar-container--user-absent': lessonMember.status === 'canceled'}"
            >
              <img
                class="notify__members-avatar"
                :src="lessonMember.avatar"
                alt="user image"
              >
            </div>
          </div>
        </div>
      </div>
    </NotifyBlock>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NotifyBlock from '@/components/NotifyBlock.vue';
import moment from 'moment-timezone';

export default {
  components: {
    NotifyBlock,
  },
  methods: {
    ...mapActions({
      setupLessonData: 'setupLessonData',
    }),
    getFormattedUserName({ firstname, lastname }) {
      if (!firstname || !lastname) return '';
      return `${firstname} ${lastname[0]}.`;
    },
  },
  computed: {
    ...mapGetters({
      lessonSubject: 'lesson/getLessonSubject',
      lessonType: 'lesson/getLessonType',
      lessonMembersFromOppositeSide: 'getMembersFromOppositeSide',
      lessonDate: 'lesson/getLessonDate',
    }),
    isShowLessonData() {
      return this.lessonSubject && this.lessonType && this.lessonMembersFromOppositeSide && this.lessonDate;
    },
    formattedLessonDate() {
      return moment(this.lessonDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm');
    },
    getFilteredMembers() {
      const members = [];

      this.lessonMembersFromOppositeSide.forEach((member) => {
        const foundMember = members.find((m) => m.id === member.id && m.status === member.status);
        if (!foundMember) members.push(member);
      });

      const activeMembers = members.filter((member) => member.status !== 'canceled');
      const notActiveMembers = members.filter((member) => member.status === 'canceled');

      const uniqueNotActiveMembers = notActiveMembers.filter((member) => {
        const foundMember = activeMembers.find((m) => m.id === member.id);
        return !foundMember;
      });

      return [...activeMembers, ...uniqueNotActiveMembers];
    },
  },
};
</script>

<style scoped lang="scss">
.notify-container {
  max-width: 500px;
  margin: 0 auto 20px;
  width: calc(100% - 20px);
}
.notify {
  font-size: 16px;

  &__content {

  }
  &__row {
    margin-bottom: 6px;
  }
  &__text-bold {
    font-weight: 500;
  }
  &__members-container {
    border-top: 1px solid $color-brand;
    padding-top: 10px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  &__members-avatar-container {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px solid $color-brand;
    margin-left: -10px;
    object-fit: cover;
    overflow: hidden;

    &--user-absent {
      border-color: $color-accent;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        background: $color-accent;
        opacity: .2;
      }
    }
  }
  &__members-avatar {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
