<template>
  <div class="badge">
    <i v-if="isTutor" class="las la-graduation-cap"></i>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'UserNameBadge',
  props: {
    isTutor: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.badge {
  position: absolute;
  max-width: 100%;
  left: 0;
  top: 0;
  line-height: 24px;
  background: pink;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
  border-radius: 5px;
  height: 25px;
  background: $color-accent;
  color: #fff;

  > i {
    margin-right: 8px;
    font-size: 23px;
    vertical-align: middle;
  }
}
</style>
