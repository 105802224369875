import axios from 'axios';

export default {
  install(app, config) {
    const optionsArr = Object.entries(config);
    optionsArr.forEach((option) => {
      const axiosInstance = axios.create(option[1]);
      window[option[0]] = axiosInstance;
      app.config.globalProperties[`$${option[0]}`] = axiosInstance;
    });
  },
};
