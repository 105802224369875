import moment from 'moment-timezone';
import {
  DATE_FORMAT_STR,
  LESSON_DURATION_SECONDS,
  SHOW_TIMER_WAIT_MEMBERS_POPUP_ON_SEC,
  HIDE_TIMER_WAIT_MEMBERS_POPUP_ON_SEC,
} from '@/helpers/variables';

export default {
  namespaced: true,

  state: {
    localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    lessonStartDate: null,
    lessonFinishDate: null,
    currentDate: null,
    showTimerPopupDate: null,
    hideTimerPopupDate: null,
  },
  getters: {
    localTimezone: (state) => state.localTimezone,
    lessonStartDate: (state) => state.lessonStartDate,
    lessonFinishDate: (state) => state.lessonFinishDate,
    showTimerPopupDate: (state) => state.showTimerPopupDate,
    hideTimerPopupDate: (state) => state.hideTimerPopupDate,
    currentDate: (state) => state.currentDate,
    lessonDuration: (state) => {
      /**
       * Returns count of seconds from start lesson date timestamp to current date timestamp
       */
      if (!state.lessonStartDate || !state.currentDate) return 0;
      const diff = Math.trunc(state.currentDate.diff(state.lessonStartDate) / 1000);
      return diff;
    },
    getFormattedLessonDuration: (state, getters) => {
      /**
       * Returns formatted lesson time MM:SS. Able to show time before lesson and lesson duration.
       */
      const { lessonDuration } = getters;
      const minutes = Math.abs(Math.trunc(lessonDuration / 60));
      let seconds = Math.abs(lessonDuration % 60);
      if (String(seconds).length < 2) seconds = `0${seconds}`;
      return `${minutes}:${seconds}`;
    },
    getFormattedCountdownTime: (state, getters) => {
      /**
       * Returns formatted countdown lesson time in format MM:SS.
       */
      const { lessonStartDate, lessonFinishDate, lessonDuration } = getters;
      const totalLessonDurationSec = (lessonFinishDate - lessonStartDate) / 1000;
      const timeDiff = totalLessonDurationSec - lessonDuration;
      const minutes = Math.floor(timeDiff / 60);
      let seconds = Math.abs(timeDiff % 60);
      if (String(seconds).length < 2) seconds = `0${seconds}`;
      return `${minutes}:${seconds}`;
    },
    percentageOf2MinutesCountDownTimer(state, getters) {
      /**
       * Returns the percentage of time remaining from 2 minutes (until the end of the lesson)
       */
      const { lessonStartDate, lessonFinishDate, lessonDuration } = getters;
      const totalLessonDurationSec = (lessonFinishDate - lessonStartDate) / 1000;
      let timeDiffSec = totalLessonDurationSec - lessonDuration;
      const countDownTimerDurationSec = 60 * 2;
      if (timeDiffSec > countDownTimerDurationSec) timeDiffSec = countDownTimerDurationSec;
      let restPercent = timeDiffSec / (countDownTimerDurationSec / 100);
      if (restPercent < 0) restPercent = 0;
      if (restPercent > 100) restPercent = 100;
      return restPercent;
    },
    isLessonStarted: (state) => {
      /**
       * Returns boolean flag showing is started lesson or not
       */
      if (!state.currentDate || !state.lessonStartDate) return false;
      return state.currentDate.isBetween(state.lessonStartDate, state.lessonFinishDate);
    },
    isLessonFinished: (state) => {
      /**
       * Returns boolean flag showing is started finished or not
       */
      if (!state.currentDate || !state.lessonFinishDate) {
        return false;
      }
      return state.currentDate.isAfter(state.lessonFinishDate);
    },
    is2MinutesBeforeTheLessonEnd: (state) => {
      /**
       * Returns a boolean flag indicating whether less than 2 minutes are left before the end of the lesson or not
       */
      if (state.currentDate) {
        const twoMinutesBeforeTheLessonEnd = moment(state.lessonFinishDate).subtract(2, 'minutes');
        return state.currentDate.isBetween(twoMinutesBeforeTheLessonEnd, state.lessonFinishDate);
      }
      return false;
    },
  },
  mutations: {
    SETUP_LESSON_START_DATE: (state, date) => { state.lessonStartDate = date; },
    SETUP_LESSON_FINISH_DATE: (state, date) => { state.lessonFinishDate = date; },
    SET_SHOW_TIMER_POPUP_DATE: (state, date) => { state.showTimerPopupDate = date; },
    SET_HIDE_TIMER_POPUP_DATE: (state, date) => { state.hideTimerPopupDate = date; },
    SETUP_CURRENT_DATE: (state, date) => { state.currentDate = date; },
  },
  actions: {
    setupLessonStartDate({ state, commit, rootState }) {
      /**
       * Sets the start time of the lesson (taking into account the local time zone)
       */
      const userServiceTimezone = rootState.userData.timezone;
      const lessonServiceDate = rootState.lesson.lessonDate;
      const { localTimezone } = state;
      const lessonStartDate = moment
        .tz(lessonServiceDate, DATE_FORMAT_STR, userServiceTimezone)
        .tz(localTimezone);
      // const lessonStartDate = moment
      //   .tz('2022-09-22 12:37', DATE_FORMAT_STR, userServiceTimezone)
      //   .tz(localTimezone);
      commit('SETUP_LESSON_START_DATE', lessonStartDate);
    },
    setupLessonFinishDate({ state, commit }) {
      /**
       * Sets the finish time of the lesson (taking into account the local time zone)
       */
      const { lessonStartDate } = state;
      const lessonFinishDate = moment(lessonStartDate).add(LESSON_DURATION_SECONDS, 'seconds');
      commit('SETUP_LESSON_FINISH_DATE', lessonFinishDate);
    },
    setupTimerPopupVisibilityTimeRange({ state, commit }) {
      /**
       * Sets up show and hide timestamps of waiting members popup
       */
      const { lessonStartDate } = state;
      const showTimerPopupDate = moment(lessonStartDate)
        .add(SHOW_TIMER_WAIT_MEMBERS_POPUP_ON_SEC, 'seconds');
      const hideTimerPopupDate = moment(lessonStartDate)
        .add(HIDE_TIMER_WAIT_MEMBERS_POPUP_ON_SEC, 'seconds');
      commit('SET_SHOW_TIMER_POPUP_DATE', showTimerPopupDate);
      commit('SET_HIDE_TIMER_POPUP_DATE', hideTimerPopupDate);
    },
    setupCurrentDate({ commit }) {
      /**
       * Sets up current date in moment format
       */
      const currentDate = moment(new Date());
      commit('SETUP_CURRENT_DATE', currentDate);
    },
  },
};
