<template>
  <div class="overlay">
    <div class="modal">
      <div class="modal__title">{{ $t('tutorLeaveLessonModal.selectAction') }}</div>
      <div class="modal__buttons-container">
        <base-button
          theme="white-accent"
          :fill="true"
          @click="$emit('leaveLesson')"
        >
          {{ $t('tutorLeaveLessonModal.leaveLesson') }}
        </base-button>
        <base-button
          :fill="true"
          @click="$emit('finishLesson')"
        >
          {{ $t('tutorLeaveLessonModal.finishLesson') }}
        </base-button>
      </div>
      <div class="modal__close-btn" @click="$emit('closeModal')">
        <i class="las la-times"></i>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue';

export default {
  name: 'TutorLeaveLessonModal',
  emits: [
    'closeModal',
    'leaveLesson',
    'finishLesson',
  ],
  components: { BaseButton },

};
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  border: 2px solid $color-border;
  position: relative;

  &__title {
    text-align: center;
    font-size: 18px;
    padding: 0 40px;
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  &__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #fb236a;
    border-radius: 5px;
  }
}
</style>
