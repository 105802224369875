<template>
  <div class="leave-call">
    <div class="leave-call__header">
      <div class="leave-call__header-left"></div>
      <div class="leave-call__header-center">
        <img class="leave-call__header-logo" src="@/assets/images/logo-dark.png" alt="logo">
      </div>
      <div class="leave-call__header-right"></div>
    </div>
    <div class="leave-call__content">
      <div class="review">
        <div class="review__title">{{ $t('leaveCallPageStudent.lessonCompleted') }}</div>
        <div v-if="tutor" class="review__content">
          <div class="review__content-text">
            {{ $t('leaveCallPageStudent.leaveRecommendationAndMark') }}
          </div>
          <div class="review__header-block">
            <img
              v-if="tutor.avatar"
              class="review__header-block-avatar"
              :src="tutor.avatar"
              alt="avatar"
            >
            <div
              v-if="tutor.firstname && tutor.lastname"
              class="review__header-block-username"
            >
              {{ `${tutor.firstname} ${tutor.lastname[0]}.` }}
            </div>
          </div>
          <textarea
            v-model="form.review"
            id="review" class="review__textarea"
            :placeholder="$t('leaveCallPageStudent.reply') + '...'"
          ></textarea>
<!--          <input-->
<!--            v-model="form.mark"-->
<!--            class="review__mark"-->
<!--            type="number"-->
<!--            min="1"-->
<!--            max="5"-->
<!--            placeholder="Оценка"-->
<!--          >-->
          <v-select
            class="review__mark-select"
            v-model="form.mark"
            :options="[1, 2, 3, 4, 5]"
            :clearable="false"
            :placeholder="$t('leaveCallPageStudent.mark')"
          ></v-select>
          <base-button @click="sendReview" fill>{{ $t('leaveCallPageStudent.send') }}</base-button>
        </div>
        <base-button @click="redirect" :theme="'white'">{{ $t('leaveCallPageStudent.goToTeme') }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import { mapActions, mapGetters } from 'vuex';

const { VUE_APP_TEME_URL, VUE_APP_ENVIRONMENT: ENV } = process.env;

export default {
  components: {
    BaseButton,
  },
  data: () => ({
    tutor: null,
    form: {
      review: '',
      mark: 5,
    },
  }),
  mounted() {
    const [tutor] = this.allLessonMembers
      .filter((member) => member.role === 'tutor')
      .filter((member) => member.status === 'connected' || member.status === 'completed');
    this.tutor = tutor;

    if (window.localStorage.getItem('roomId')) {
      window.localStorage.removeItem('roomId');
      window.localStorage.setItem('redirectIfPressBack', '/leave-call-student');
    }
  },
  methods: {
    ...mapActions({
      sendReviewToTutor: 'sendReviewToTutor',
      checkOnExistReviewForTutor: 'checkOnExistReviewForTutor',
    }),
    redirect() {
      window.location = VUE_APP_TEME_URL;
    },
    sendReview() {
      if (!this.form.mark) return;
      this.sendReviewToTutor({
        lesson_id: this.lessonId,
        grade: this.form.mark,
        text: this.form.review,
      })
        .then(() => {
          this.$notify({ type: 'success', title: this.$t('leaveCallPageStudent.notify.resultSuccess') });
          setTimeout(this.redirect.bind(this), 1000);
        })
        .catch((e) => {
          if (ENV === 'dev') console.log(e);
          this.$notify({ type: 'error', title: this.$t('leaveCallPageStudent.notify.resultFailed') });
        });
    },
  },
  computed: {
    ...mapGetters({
      allLessonMembers: 'getAllLessonMembers',
      roomId: 'getRoomId',
      lessonId: 'getLessonId',
    }),
  },
};
</script>

<style scoped lang="scss">
.leave-call {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    height: 70px;
    border-bottom: 1px solid $color-border;
    align-items: center;
    padding: 0 15px;
    flex-shrink: 0;
  }
  &__header-left {
    flex-grow: 1;
  }
  &__header-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  &__header-logo {
    height: 36px;
  }
  &__header-right {
    flex-grow: 1;
    display: flex;
    justify-content: end;
  }
  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    padding: 15px;
    @media screen and (orientation: landscape) and (max-height: 500px) {
      align-items: flex-start;
      overflow: auto;
    }
  }
}
.review {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  text-align: center;
  @media screen and (orientation: landscape) and (max-height: 500px) {
    margin-top: 20px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    color: $color-text;
    margin-bottom: 15px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-border;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
  }
  &__content-text {
    font-size: 18px;
  }
  &__header-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
  &__header-block-avatar {
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 5px;
    border: 3px solid $color-accent;
    object-fit: cover;
  }
  &__header-block-username {
    font-size: 18px;
    margin-bottom: 15px;
  }
  &__textarea {
    width: 100%;
    color: #474747;
    padding: 10px;
    border: 2px solid $color-border;
    border-radius: 8px;
    margin-bottom: 10px;
    height: 100px;
    outline: none;
    resize: none;
  }
  &__mark {
    width: 100%;
    color: #474747;
    padding: 16px 15px;
    border: 2px solid $color-border;
    border-radius: 8px;
    margin-bottom: 15px;
    outline: none;
  }
  &__mark-select {
    margin-bottom: 15px;
  }
}
</style>
