<template>
  <div class="card">
    <div class="card_icon">
      <i class="las la-comments"></i>
    </div>
    <div class="card__text1">{{ $t('chat.noMessagesInChat') }}</div>
    <div class="card__text2">{{ $t('chat.startAConversationFirst') }}</div>
  </div>
</template>

<style lang="scss">
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #ffd6e2;
  border: 1px solid $color-accent;
  border-radius: 10px;
  color: $color-accent;

  &_icon {
    font-size: 36px;
    margin-bottom: 10px;
  }

  &__text1 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
  }

  &__text2 {
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
