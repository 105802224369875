<template>
  <div class="bottom-bar">
    <div class="bottom-bar__left-side">
      <UserNameBadge :isTutor="isTutor" :text="mainBadgeText"/>
    </div>
    <div class="bottom-bar__right-side">
      <IconBadge v-if="needToShowMicrophoneBadge && isMicrophoneEnable" class="bottom-bar__video-muted-mic">
        <i class="las la-microphone-slash"></i>
      </IconBadge>
      <IconBadge class="bottom-bar__video-fullscreen" @click="this.$emit('makePrimaryVideo', this.peerId)">
        <i v-if="isPrimaryScreen" class="las la-compress"></i>
        <i v-else class="las la-expand"></i>
      </IconBadge>
    </div>
  </div>
</template>

<script>
import UserNameBadge from '@/components/VideoCard/UserNameBadge.vue';
import IconBadge from '@/components/VideoCard/IconBadge.vue';

export default {
  name: 'BottomVideoCardBar',
  components: {
    UserNameBadge,
    IconBadge,
  },
  emits: ['makePrimaryVideo'],
  props: {
    peerId: {
      type: String,
      required: true,
    },
    isTutor: {
      type: Boolean,
      required: true,
    },
    mainBadgeText: {
      type: String,
      required: true,
    },
    isMicrophoneEnable: {
      type: Boolean,
      required: true,
    },
    needToShowMicrophoneBadge: {
      type: Boolean,
      default: false,
    },
    isPrimaryScreen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.bottom-bar {
  height: 25px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;

  &__left-side {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
  }

  &__right-side {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
  }

  &__video-muted-mic {
    margin-left: 8px;
  }

  &__video-fullscreen {
    margin-left: 8px;
  }
}
</style>
