export default {
  namespaced: true,

  state: () => ({
    lessonDate: null,
    subject: null,
    lessonType: null,
  }),
  getters: {
    getLessonDate: (state) => state.lessonDate,
    getLessonSubject: (state) => state.subject,
    getLessonType: (state) => state.lessonType,
  },
  mutations: {
    SETUP_LESSON_DATE: (state, date) => { state.lessonDate = date; },
    SETUP_LESSON_SUBJECT: (state, subject) => { state.subject = subject; },
    SETUP_LESSON_TYPE: (state, lessonType) => { state.lessonType = lessonType; },
  },
  actions: {

  },
};
