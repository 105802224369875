<template>
  <div class="timer">
    <div class="timer__close-button" @click="$emit('closeHandler')">
      <i class="las la-times"></i>
    </div>
    <div class="timer__title">
      <template v-if="userData?.role === 'tutor'">{{ $t('timerPopup.waitingForStudents') }}</template>
      <template v-if="userData?.role === 'student'">{{ $t('timerPopup.waitingForTutor') }}</template>
    </div>
    <div class="timer__left-time">
      <ProgressRing
        :radius="60"
        :progress="getTimerPercent()"
        :stroke-width="5"
      >
        <div class="timer__ring-timer">{{ formattedCountdownTime }}</div>
      </ProgressRing>
    </div>
  </div>
</template>

<script>
import ProgressRing from '@/components/ProgressRing.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ProgressRing,
  },
  emits: ['closeHandler'],
  methods: {
    getTimerPercent() {
      if (!this.hideTimerPopupDate || !this.showTimerPopupDate || !this.currentDate) return '';
      const popupActivitySec = this.hideTimerPopupDate.unix() - this.showTimerPopupDate.unix();
      const restTimeDiffInSec = this.hideTimerPopupDate.unix() - this.currentDate.unix();
      let percent = (100 / popupActivitySec) * restTimeDiffInSec;
      if (percent < 0) percent = 0;
      if (percent > 100) percent = 100;
      return percent;
    },
  },
  computed: {
    ...mapGetters({
      userData: 'getUserData',
      lessonStartDate: 'lessonTimer/lessonStartDate',
      showTimerPopupDate: 'lessonTimer/showTimerPopupDate',
      hideTimerPopupDate: 'lessonTimer/hideTimerPopupDate',
      currentDate: 'lessonTimer/currentDate',
    }),
    timeDiff() {
      if (!this.lessonStartDate || !this.currentDate) {
        return '';
      }
      return Math.trunc((this.currentDate - this.lessonStartDate) / 1000);
    },
    formattedCountdownTime() {
      const timeRange = (this.hideTimerPopupDate - this.currentDate) / 1000;
      const minutes = Math.trunc(timeRange / 60);
      let seconds = Math.trunc(timeRange % 60);
      if (String(seconds).length < 2) seconds = `0${seconds}`;
      return `${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.timer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  width: 340px;
  z-index: 1000;

  &__close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: $color-accent;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    &:hover {
      background: #eee;
    }
  }

  &__title {
    font-size: 20px;
    margin-bottom: 25px;
    padding: 0 20px;
    text-align: center;
  }

  &__left-time {
    text-align: center;
  }

  &__timer {
    margin-bottom: 10px;
  }

  &__line-container {
    width: 100%;
    height: 6px;
    background: #ccc;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
  }

  &__inner-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    background: $color-accent;
  }

  &__tutor-not-come-in {
    text-align: center;
  }

  &__btn-container {
    display: flex;
    margin-top: 35px;
  }
}
</style>
