import { v4 as uuidv4 } from 'uuid';
import Peer from 'peerjs-with-transceiver';
import { mapActions, mapGetters } from 'vuex';
import ActionsPanel from '@/components/ActionsPanel.vue';
import BaseButton from '@/components/BaseButton.vue';
import ConnectionPopup from '@/components/ConnectionPopup.vue';
import HeaderCallRoom from '@/components/HeaderCallRoom.vue';
import FooterCallRoom from '@/components/FooterCallRoom.vue';
import BottomVideoCardBar from '@/components/VideoCard/BottomVideoCardBar.vue';

const {
  VUE_APP_ENVIRONMENT: ENV,
} = process.env;

export default {
  data: () => ({
    myPeer: null,
    myScreenSharePeer: null,
    peers: {},
    peerIdPrimaryScreen: null,
    notifySound: null,
    playSoundOnNewUser: false,
    soundEmittedFromPeer: null,
    volumeEmitSensitivity: 0.45,
    isConnectionPopupShown: false,
  }),
  components: {
    ActionsPanel,
    BaseButton,
    ConnectionPopup,
    HeaderCallRoom,
    FooterCallRoom,
    BottomVideoCardBar,
  },
  methods: {
    ...mapActions({
      changeNotifyStringStatus: 'changeNotifyStringStatus',
      setLocalCameraStream: 'setLocalCameraStream',
      toggleCameraStatus: 'toggleCameraStatus',
      toggleMicrophoneStatus: 'toggleMicrophoneStatus',
      setLocalScreenStream: 'setLocalScreenStream',
      setupLessonData: 'setupLessonData',
    }),

    socketConnectHandler() {
      this.openPeerHandler(this.myPeer.id);
      this.isConnectionPopupShown = false;
      this.$notify({ type: 'success', title: this.$t('callPage.notify.connectionEstablished') });
    },
    socketDisconnectHandler() {
      if (this.myScreenSharePeer && this.localScreenStream) {
        this.setLocalScreenStream(null);
        this.myScreenSharePeer.disconnect();
      }
      if (this.peerIdPrimaryScreen !== this.myPeer.id) {
        this.peerIdPrimaryScreen = null;
      }
      this.peers = {};
      this.isConnectionPopupShown = true;
      this.$notify({ type: 'error', title: this.$t('callPage.notify.connectionLost') });
    },
    newUserJoinedHandler(peerData) {
      const call = this.myPeer.call(peerData.peerId, this.localCameraStream, { addTransceivers: true });
      call.on('stream', async (stream) => {
        this.peers[peerData.peerId] = {
          ...peerData,
          stream,
          call,
        };
        await this.setupLessonData();
        if (this.playSoundOnNewUser) {
          this.notifySound.play();
        }
      });
    },
    userDisconnectedHandler({ peerId }) {
      if (this.peerIdPrimaryScreen === peerId) {
        this.peerIdPrimaryScreen = null;
      }
      delete this.peers[peerId];
    },
    acceptCallHandler(call) {
      call.answer(this.localCameraStream, { addTransceivers: true });
      call.on('stream', (stream) => {
        this.peers[call.peer] = { stream, call };
        this.$socket.emit('FETCH_PEER_DATA', {
          roomId: this.roomId,
          peerId: call.peer,
        });
      });
    },
    setPeerData(peerData) {
      this.peers[peerData.peerId] = {
        ...this.peers[peerData.peerId],
        ...peerData,
      };
    },
    openPeerHandler(peerId) {
      this.$socket.emit('JOIN_ROOM', {
        roomId: this.roomId,
        roomType: this.roomType,
        peerId,
        userName: this.getFormattedUsername({
          firstname: this.userData.firstname,
          lastname: this.userData.lastname,
        }),
        userId: this.userData.id,
        userAvatar: this.userData.avatar,
        isTutor: this.userData.role === 'tutor',
        isMicrophoneEnable: this.isMicrophoneEnable,
        isCameraEnable: this.isCameraEnable,
        isScreenSharingStream: false,
      });
    },
    startedScreenSharing(peerId) {
      this.$socket.emit('STARTED_SCREEN_SHARING', {
        roomId: this.roomId,
        peerId,
        userName: this.getFormattedUsername({
          firstname: this.userData.firstname,
          lastname: this.userData.lastname,
        }),
        userId: this.userData.id,
        userAvatar: this.userData.avatar,
        isTutor: this.userData.role === 'tutor',
        isMicrophoneEnable: this.isMicrophoneEnable,
        isCameraEnable: false,
        isScreenSharingStream: true,
      });
    },
    closePeerHandler() {
      this.$socket.disconnect();
    },
    async toggleScreenStream() {
      if (!this.localScreenStream) {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            cursor: 'always',
            frameRate: { max: 15 },
          },
          audio: false,
        });
        this.setLocalScreenStream(stream);
        const peerUuid = uuidv4();
        this.myScreenSharePeer = new Peer(peerUuid);
        this.myScreenSharePeer.on('call', (call) => {
          call.answer(stream, { addTransceivers: true });
        });
        this.peerIdPrimaryScreen = this.myScreenSharePeer.id;
        this.myScreenSharePeer.on('open', this.startedScreenSharing);
        stream.getVideoTracks()[0].onended = this.stopScreenSharing;
      } else {
        this.stopScreenSharing();
      }
    },
    stopScreenSharing() {
      this.peerIdPrimaryScreen = null;
      this.$socket.emit('USER_STOPPED_SCREEN_SHARING', {
        roomId: this.roomId,
        peerId: this.myScreenSharePeer.id,
      });
      this.localScreenStream.getTracks()
        .forEach((track) => {
          track.stop();
        });
      this.setLocalScreenStream(null);
      this.myScreenSharePeer.disconnect();
    },
    sendNewStreamToOtherPeers(stream) {
      const videoTrack = stream.getTracks()
        .find((track) => track.kind === 'video');
      Object.values(this.myPeer.connections)
        .forEach((connection) => {
          connection[0].peerConnection
            .getSenders()[1]
            .replaceTrack(videoTrack)
            .catch((e) => {
              if (ENV === 'dev') console.error(e);
            });
        });
    },
    makePrimaryVideo(peerId) {
      if (this.peerIdPrimaryScreen === peerId) {
        this.peerIdPrimaryScreen = null;
      } else {
        this.peerIdPrimaryScreen = peerId;
      }
    },
    toggleCamera() {
      this.toggleCameraStatus();
      this.$socket.emit('USER_CHANGED_CAMERA_STATUS', {
        roomId: this.roomId,
        peerId: this.myPeer.id,
        isCameraEnable: this.isCameraEnable,
      });
    },
    toggleMicrophone() {
      this.toggleMicrophoneStatus();
      this.$socket.emit('USER_CHANGED_MICROPHONE_STATUS', {
        roomId: this.roomId,
        peerId: this.myPeer.id,
        isMicrophoneEnable: this.isMicrophoneEnable,
      });
    },
    updateRemoteUserMicStatus({
      peerId,
      isMicrophoneEnable,
    }) {
      this.peers[peerId].isMicrophoneEnable = isMicrophoneEnable;
    },
    updateRemoteUserCamStatus({
      peerId,
      isCameraEnable,
    }) {
      this.peers[peerId].isCameraEnable = isCameraEnable;
    },
    makePeerIdScreenAsPrimary({ peerId }) {
      this.peerIdPrimaryScreen = peerId;
    },
    onRemotePeerScreenSharing(peerData) {
      if (this.localScreenStream) {
        this.stopScreenSharing();
      }
      const call = this.myPeer.call(peerData.peerId, this.localCameraStream, { addTransceivers: true });
      call.on('stream', (stream) => {
        this.peers[peerData.peerId] = {
          ...peerData,
          stream,
        };
        this.makePeerIdScreenAsPrimary({ peerId: peerData.peerId });
      });
    },
    startCaptureVoiceEmitting() {
      const ctx = this;
      let maxLevelL = 0;
      let oldLevelL = 0;
      const audioContext = new AudioContext();
      const microphone = audioContext.createMediaStreamSource(this.localCameraStream);
      const javascriptNode = audioContext.createScriptProcessor(1024, 1, 1);

      microphone.connect(javascriptNode);
      javascriptNode.connect(audioContext.destination);
      javascriptNode.onaudioprocess = function (event) {
        const inputL = event.inputBuffer.getChannelData(0);
        let instantL = 0.0;
        let sumL = 0.0;
        for (let i = 0; i < inputL.length; i += 1) {
          sumL += inputL[i] * inputL[i];
        }
        instantL = Math.sqrt(sumL / inputL.length);
        maxLevelL = Math.max(maxLevelL, instantL);
        instantL = Math.max(instantL, oldLevelL - 0.008);
        oldLevelL = instantL;
        if (instantL / maxLevelL > ctx.volumeEmitSensitivity) {
          ctx.setupSoundEmitter(ctx.myPeer.id);
          ctx.$socket.emit('EMIT_SOUND', {
            roomId: ctx.roomId,
            peerId: ctx.myPeer.id,
          });
        }
      };
    },
    onUserEmitSound({ peerId }) {
      this.setupSoundEmitter(peerId);
    },
    setupSoundEmitter(peerId) {
      this.soundEmittedFromPeer = peerId;
      setTimeout(() => { this.soundEmittedFromPeer = null; }, 1000);
    },
    getFormattedUsername({ firstname, lastname }) {
      return `${firstname} ${lastname[0]}.`;
    },
    newMessageNotify() {
      if (!this.$store.state.modals.chatIsOpen) {
        this.$notify({ type: 'success', title: this.$t('chat.newMessageInTheChat') });
      }
    },
  },
  computed: {
    ...mapGetters({
      roomId: 'getRoomId',
      roomType: 'roomType',
      notifyStringStatus: 'getNotifyStringStatus',
      localCameraStream: 'getLocalCameraStream',
      isCameraEnable: 'isCameraEnable',
      isMicrophoneEnable: 'isMicrophoneEnable',
      localScreenStream: 'getLocalScreenStream',
      userData: 'getUserData',
      allLessonMembers: 'getAllLessonMembers',
      lessonDate: 'lesson/getLessonDate',
      isCameraExists: 'isCameraExists',
    }),
    totalCountOfScreens() {
      let screensCount = Object.keys(this.peers).length || 0;
      if (this.localScreenStream) screensCount += 1;
      if (this.localCameraStream) screensCount += 1;
      return screensCount;
    },
  },
};
