<template>
  <div v-if="serviceCallData" class="notify-container">
    <NotifyBlock class="notify">
      <div class="notify__content">
        <p class="notify__title">{{ serviceCallData.title }}</p>
        <div class="notify__members-container">
          <div
            v-for="member of filteredMembers"
            :key="member.id"
            class=" tooltip"
            :data-tooltip-text="member.name"
          >
            <div class="notify__members-avatar-container">
              <img
                class="notify__members-avatar"
                :src="member.avatar"
                alt="user image"
              >
            </div>
          </div>
        </div>
      </div>
    </NotifyBlock>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NotifyBlock from '@/components/NotifyBlock.vue';

export default {
  components: {
    NotifyBlock,
  },
  methods: {
    ...mapActions({
      setupLessonData: 'setupLessonData',
    }),
    getFormattedUserName({ firstname, lastname }) {
      if (!firstname || !lastname) return '';
      return `${firstname} ${lastname[0]}.`;
    },
  },
  computed: {
    ...mapGetters({
      serviceCallData: 'serviceCallData',
      userData: 'getUserData',
    }),
    filteredMembers() {
      if (!this.serviceCallData?.members?.length) return [];
      return this.serviceCallData?.members.filter((m) => m.id !== this.userData.id);
    },
  },
};
</script>

<style scoped lang="scss">
.notify-container {
  max-width: 500px;
  margin: 0 auto 20px;
  width: calc(100% - 20px);
}
.notify {
  font-size: 16px;
  &__title {
    text-align: center;
    font-size: 18px;
  }
  &__members-container {
    border-top: 1px solid $color-brand;
    padding-top: 10px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  &__members-avatar-container {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px solid $color-brand;
    margin-left: -10px;
    object-fit: cover;
    overflow: hidden;

    &--user-absent {
      border-color: $color-accent;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        background: $color-accent;
        opacity: .2;
      }
    }
  }
  &__members-avatar {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
