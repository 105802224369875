<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';
import helpers from '@/helpers';

const { VUE_APP_ENVIRONMENT: ENV, VUE_APP_TOKEN_COOKIE_NAME } = process.env;

export default {
  async mounted() {
    const token = this.$cookies.get(VUE_APP_TOKEN_COOKIE_NAME);
    const { roomType, roomId } = this.$route.params;

    if (!token || !roomType || !roomId) helpers.redirectOnFailure();

    await this.setUserAuthToken(token);
    await this.setupRoomId(roomId);
    await this.setRoomType(roomType);

    try {
      await this.setupUserData();
      await this.$router.push('/preview');
    } catch (e) {
      if (ENV === 'dev') console.log(e);
      helpers.redirectOnFailure();
    }
  },
  methods: {
    ...mapActions({
      setupRoomId: 'setupRoomId',
      setUserAuthToken: 'setUserAuthToken',
      setupUserData: 'setupUserData',
      setRoomType: 'setRoomType',
    }),
  },
};
</script>
