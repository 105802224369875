import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import PreviewPage from '@/pages/PreviewPage.vue';
import CallPage from '@/pages/CallPage.vue';
import ServiceCallPage from '@/pages/ServiceCallPage.vue';
import LeaveCallPageStudent from '@/pages/LeaveCallPageStudent.vue';
import LeaveCallPageTutor from '@/pages/LeaveCallPageTutor.vue';
import InitPage from '@/pages/InitPage.vue';
import NotFoundPage from '@/pages/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/init/:roomType/:roomId',
    component: InitPage,
  },
  {
    path: '/preview',
    component: PreviewPage,
  },
  {
    path: '/call',
    component: CallPage,
  },
  {
    path: '/service-call',
    component: ServiceCallPage,
  },
  {
    path: '/leave-call-student',
    component: LeaveCallPageStudent,
  },
  {
    path: '/leave-call-tutor',
    component: LeaveCallPageTutor,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundPage,
  },
  // {
  //   path: '/dev',
  //   component: () => import('@/pages/CallPageDev.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
