<template>
  <div class="actions-panel">
    <button class="actions-panel__toggle" @click="toggle = !toggle"><i class="las la-braille"></i></button>
    <div class="actions-panel__menu" :class="{'is-show': toggle}">
      <div v-if="needToShowGameBtn">
        <base-button
          theme="white"
          @click="game = true"
        >
          <i class="las la-gamepad"></i>
        </base-button>
      </div>
      <div v-if="needToShowDrawingBoardBtn">
        <base-button
          theme="white"
          @click="board = true"
        >
          <i class="las la-edit"></i>
        </base-button>
      </div>
      <div v-if="needToShowChatBtn">
        <base-button
          theme="white"
          @click="openChat"
        >
          <i class="las la-comments" :class="{'is-new-messages': isNewMessageInChat}"></i>
        </base-button>
      </div>
    </div>
    <div v-if="game" class="game-wrap">
      <button class="game-wrap__close" @click="game = false"> <i class="las la-times"></i> </button>
      <iframe src="./games/hurry-up-runner/index.html" frameborder="0" title="hurry-up-runner"></iframe>
    </div>
    <div v-if="board" class="game-wrap game-wrap--board">
      <button class="game-wrap__close" @click="board = false"> <i class="las la-times"></i> </button>
      <iframe :src="boardUrl" frameborder="0" title=""></iframe>
    </div>
    <div v-if="isChatOpen">
      <Chat
        @closeChat="closeChat"
      ></Chat>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';
import Chat from '@/components/Chat/Chat.vue';

export default {
  components: { BaseButton, Chat },
  props: {
    needToShowGameBtn: {
      type: Boolean,
      default: true,
    },
    needToShowDrawingBoardBtn: {
      type: Boolean,
      default: true,
    },
    needToShowChatBtn: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    game: false,
    toggle: false,
    board: false,
    isNewMessageInChat: false,
  }),
  computed: {
    ...mapGetters({
      isChatOpen: 'modals/chatIsOpen',
    }),
    boardUrl() {
      return `https://wbo.ophir.dev/boards/board_${this.$store.getters.getRoomId}?lang=${this.$i18n.locale}`;
    },
  },
  mounted() {
    this.$socket.on('IS_NEW_MESSAGE', this.updateNewMessageIndicatorHandler);
  },
  methods: {
    ...mapActions({
      changeChatVisibility: 'modals/changeChatVisibility',
    }),
    openChat() {
      this.changeChatVisibility(true);
      if (this.isNewMessageInChat) {
        this.isNewMessageInChat = false;
      }
    },
    closeChat() {
      this.changeChatVisibility(false);
    },
    updateNewMessageIndicatorHandler() {
      if (!this.isChatOpen) {
        this.isNewMessageInChat = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.actions-panel{
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 100;
  @media screen and (max-width: 800px){
    left: 0;
    top: 0;
  }
  .las{
    font-size: 22px;
  }

  &__menu{
    display: none;
    @media screen and (min-width: 801px){
      display: block!important;
    }
    &.is-show{
      @media screen and (max-width: 800px){
        display: block!important;
        margin-top: 30px;
        margin-left: 10px;
      }
    }
    >div{
      margin-bottom: 10px;
    }
  }

  &__toggle{
    background-color: transparent!important;
    border: none!important;
    padding: 0;
    line-height: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 19px;
    .las{
      font-size: 30px;
    }
    @media screen and (min-width: 801px){
      display: none!important;
    }
  }
}
.game-wrap{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000,0.8);
  padding: 40px 0;
  @media screen and (max-width: 991px) {
    padding: 0;
  }
  &--board{
    background-color: #fff;
    padding: 0;
    height: calc(100% - 70px);
  }
  &__close{
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
    border: none!important;
    padding: 0;
    font-size: 30px;
    line-height: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-accent;
    cursor: pointer;
  }

  iframe{
    width: 100%;
    height: 100%;
  }
}
.is-new-messages {
  color: $color-accent;
}
</style>
